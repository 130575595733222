import { useState, type ReactElement, useEffect, useContext, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'
import { GlobalDialogContext, Breadcrumbs, Button, Form, FormButtons, LoadingComponent, Mask, TextBox } from '@andyneville/tailwind-react'
import { ArrowUturnDownIcon, TrashIcon } from '@heroicons/react/24/outline'
import { type ISeason, type ISeasonAthleteWithAthlete } from '../../../../api/api'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { useGetAdminTenantQuery } from '../../reducers/apiSlice-admin-tenants'
import { rootLog } from '../../logging'
import { useEditAdminSeasonMutation, useGetAdminSeasonQuery } from '../../reducers/apiSlice-admin-seasons'
import { useEditAdminSeasonAthleteMutation, useGetAdminSeasonAthletesQuery } from '../../reducers/apiSlice-admin-season-athletes'
import unknownUser from '../../assets/user.png'
const log = rootLog.child({ module: 'SeasonDetail' })

function sortAthletes (a: ISeasonAthleteWithAthlete, b: ISeasonAthleteWithAthlete): number {
  if (a.lastName != null && a.lastName === b.lastName) {
    return a.firstName.localeCompare(b.firstName)
  }
  return a.lastName.localeCompare(b.lastName)
}

export default function AdminSeasonDetail (): ReactElement {
  const { tenantId, seasonId } = useParams()
  const {
    data: sourceSeason,
    isLoading: loadingSeason
    // isSuccess,
    // isError,
    // error
  } = useGetAdminSeasonQuery({ tenantId: tenantId ?? '', seasonId: seasonId ?? '' }, { skip: tenantId == null || tenantId.length === 0 || seasonId == null || seasonId.length === 0 })
  const {
    data: athletes = [],
    isLoading: loadingAthletes
    // isSuccess,
    // isError,
    // error
  } = useGetAdminSeasonAthletesQuery({ tenantId: tenantId ?? '', seasonId: seasonId ?? '' }, { skip: tenantId == null || tenantId.length === 0 || seasonId == null || seasonId.length === 0 })
  const {
    data: tenant
  } = useGetAdminTenantQuery(tenantId ?? '', { skip: tenantId == null || tenantId.length === 0 })
  const [updateSeason, { isLoading: updateLoading }] = useEditAdminSeasonMutation()
  const [season, setSeason] = useState<ISeason>({} as unknown as ISeason)
  const [isDirty, setIsDirty] = useState(false)
  const globalDialog = useContext(GlobalDialogContext)
  const [filter, setFilter] = useState('')
  const [editAthlete, { isLoading: editLoading }] = useEditAdminSeasonAthleteMutation()

  useEffect(() => {
    if (sourceSeason != null) {
      setSeason(sourceSeason)
      setIsDirty(false)
    }
  }, [sourceSeason])

  const setValue = (value: Partial<ISeason>): void => {
    setSeason(Object.assign({}, season, value))
    setIsDirty(true)
  }

  const doSave = async (): Promise<void> => {
    try {
      await updateSeason(season).unwrap()
      setIsDirty(false)
    } catch (e) {
      log.error('Error updating tenant', e)
      setIsDirty(true)
    }
  }

  const doDeleteAthlete = (athlete: ISeasonAthleteWithAthlete): void => {
    globalDialog.showWarning('Delete Athlete', `Are you sure you want to delete the athlete ${athlete.firstName} ${athlete.lastName}?`, 'Delete', () => {
      void editAthlete({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', id: athlete.id, deleted: true })
    })
  }

  const doRestoreAthlete = (athlete: ISeasonAthleteWithAthlete): void => {
    globalDialog.showQuestion('Restore Athlete', `Are you sure you want to restore the athlete ${athlete.firstName} ${athlete.lastName}?`, 'Restore', () => {
      void editAthlete({ tenantId: tenantId ?? '', seasonId: seasonId ?? '', id: athlete.id, deleted: false })
    })
  }

  const filteredAthletes = useMemo(() => {
    if (filter === '') {
      return athletes
    }
    return athletes.filter((athlete) => {
      if (athlete?.firstName != null && athlete.firstName.toLowerCase().includes(filter.toLowerCase())) return true
      if (athlete?.lastName != null && athlete.lastName.toLowerCase().includes(filter.toLowerCase())) return true
      if (athlete?.birthYear != null && athlete.birthYear.toString().includes(filter.toLowerCase())) return true
      return false
    })
  }, [athletes, filter])

  return (
    <>
      <Breadcrumbs routes={[{ route: '/admin/organizations', name: 'Orgs' }, { route: `/admin/organizations/${tenantId}`, name: tenant?.name ?? 'Org' }, { name: sourceSeason?.name ?? 'Season' }]} />
      <div className='sm:mt-5'></div>
      <LoadingComponent isLoading={loadingSeason}>
        <Mask show={updateLoading} message='Updating...'>
          <Form>
            <TextBox id='name' label='Season Name' columns={5} value={season.name} onChange={(value) => { setValue({ name: value }) }} />
            <FormButtons>
              <div className='flex items-center pl-4 twc-form'>
                <div className='mr-2 text-sm'>Filter:</div>
                <TextBox value={filter} onChange={setFilter} />
              </div>
              <Button label='Save' primary disabled={!isDirty} onClick={() => { void doSave() }} />
            </FormButtons>
          </Form>
        </Mask>
      </LoadingComponent>
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingComponent isLoading={loadingAthletes} isEmpty={athletes.length === 0}>
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <TableHeader name='Image' srOnly />
                    <SortableTableHeader name='First Name' />
                    <SortableTableHeader name='Last Name' sorted={1} />
                    <SortableTableHeader name='Birth Year' />
                    <SortableTableHeader name='Rating' />
                    <SortableTableHeader name='# Tryouts' />
                    <TableHeader name='Actions' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredAthletes.slice().sort(sortAthletes).map((athlete) => (
                    <tr key={athlete.id} className={athlete.deleted === true ? 'line-through' : 'no'}>
                      <TableCell>
                        <img className="w-6 h-6 rounded-full" src={athlete.headshotUrl ?? unknownUser} alt="" />
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/seasons/${seasonId}/athletes/${athlete.id}`}>{athlete.firstName}</Link>
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/seasons/${seasonId}/athletes/${athlete.id}`}>{athlete.lastName}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/seasons/${seasonId}/athletes/${athlete.id}`}>{athlete.birthYear}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/seasons/${seasonId}/athletes/${athlete.id}`}>{athlete.totalRating}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenantId}/seasons/${seasonId}/athletes/${athlete.id}`}>{athlete.tryouts?.length}</Link>
                      </TableCell>
                      <TableActionCell>
                        <div className='flex items-center'>
                          {athlete.deleted === true
                            ? <Button Icon={ArrowUturnDownIcon} waiting={editLoading} transparent onClick={() => { doRestoreAthlete(athlete) }} />
                            : <Button Icon={TrashIcon} waiting={editLoading} transparent onClick={() => { doDeleteAthlete(athlete) }} />
                          }
                        </div>
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingComponent>
          </div>
        </div>
      </div>
    </>
  )
}
