import { useContext, type ReactElement, useState, useMemo } from 'react'
import { useDeleteAdminTenantMutation, useGetAdminTenantsFullQuery } from '../../reducers/apiSlice-admin-tenants'
import { Square3Stack3DIcon, TrashIcon } from '@heroicons/react/24/outline'
import { GlobalDialogContext, PageHeader, LoadingComponent, Button, TextBox, Breadcrumbs } from '@andyneville/tailwind-react'
import { Link } from 'react-router-dom'
import TableHeader from '../../components/TableHeader'
import SortableTableHeader from '../../components/SortableTableHeader'
import TableCell from '../../components/TableCell'
import TableActionCell from '../../components/TableActionCell'
import { type ITenant, TenantStatus, AccountRole } from '../../../../api/api'
import { DateTime } from 'luxon'
import AddTenantDialog from '../../components/AddTenantDialog'
import { type RootState } from '../../store'
import { useSelector } from 'react-redux'

export type CallbackFn = () => void

export default function AdminTenants (): ReactElement {
  const { roles: myRoles } = useSelector((state: RootState) => state.auth)
  const {
    data: tenants = [],
    isLoading,
    // isSuccess,
    isError,
    error
  } = useGetAdminTenantsFullQuery()
  const globalDialog = useContext(GlobalDialogContext)
  const [showAddOrg, setShowAddOrg] = useState(false)
  const [deleteTenant, { isLoading: deleteLoading }] = useDeleteAdminTenantMutation()
  const [filter, setFilter] = useState('')

  const doDeleteOrganization = (tenant: ITenant): void => {
    globalDialog.showWarning('Delete Organization', `Are you sure you want to delete the organization ${tenant.name}?`, 'Delete Organization', () => {
      void deleteTenant({ tenantId: tenant.id })
    })
  }

  const doExportOrganizations = (): void => {
    const csvHeader = 'Name,Status,Plan,Created,Expiration,Affiliate2024,Affiliate2025,OwnerName,OwnerEmail\n'
    const csvExport = tenants.map((tenant) => {
      return `${tenant.name},${tenant.status ?? ''},${tenant.plan ?? ''},${tenant.createdAt != null ? new Date(tenant.createdAt).toLocaleDateString() : ''},${tenant.expiration != null ? new Date(tenant.expiration).toLocaleDateString() : ''},${tenant.affiliate2024 ?? ''},${tenant.affiliate2025 ?? ''},${tenant.owner?.name ?? ''},${tenant.owner?.email ?? ''}\n`
    })
    const file = new Blob([csvHeader, ...csvExport], { type: 'text/csv' })
    // anchor link
    const element = document.createElement('a')
    const url = URL.createObjectURL(file)
    element.href = url
    element.download = `CheerSync Organizations Export ${new Date().toLocaleDateString().replace(/\//g, '-')}.csv`
    // simulate link click
    document.body.appendChild(element)
    // Required for this to work in FireFox
    element.click()
    URL.revokeObjectURL(url)
  }

  const filteredTenants = useMemo(() => {
    if (filter === '') {
      return tenants
    }
    return tenants.filter((tenant) => tenant?.name?.toLowerCase().includes(filter.toLowerCase()))
  }, [tenants, filter])

  // <div className="max-h-[600px] overflow-y-auto">
  return (
    <div className="">
      <PageHeader
        title='Organizations'
        subtitle='A list of all the organizations in the system.'
        breadcrumbs={<Breadcrumbs routes={[{ name: 'Organizations' }]} />}
      >
        <Button label='Export' onClick={doExportOrganizations} />
        <Button label='Add Org' primary onClick={() => { setShowAddOrg(true) }} />
        <div className='flex items-center pl-4 twc-form'>
          <div className='mr-2 text-sm'>Filter:</div>
          <TextBox value={filter} onChange={setFilter} />
        </div>
      </PageHeader>
      <AddTenantDialog open={showAddOrg} onClose={() => { setShowAddOrg(false) }} />
      <div className="flow-root mt-8">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <LoadingComponent isLoading={isLoading || deleteLoading} isError={isError} error={error} isEmpty={tenants.length === 0}>
              <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-500">
                <thead>
                  <tr>
                    <TableHeader name='Image' srOnly/>
                    <SortableTableHeader name='Name' sorted={1} />
                    <SortableTableHeader name='Status' />
                    <SortableTableHeader name='Plan' />
                    <SortableTableHeader name='Expiration' />
                    <SortableTableHeader name='Affiliate' />
                    <SortableTableHeader name='Owner Name' />
                    <SortableTableHeader name='Owner Email' />
                    <TableHeader name='Actions' srOnly />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                  {filteredTenants.map((tenant) => (
                    <tr key={tenant.id} className={tenant.status === TenantStatus.Expired ? 'line-through' : 'no'}>
                      <TableCell>
                        {tenant.logo == null
                          ? (
                              <Square3Stack3DIcon className="w-6 h-6 rounded-full" aria-hidden="true" />
                            )
                          : (
                            <img className="w-6 h-6 rounded-full" src={tenant.logo} alt="" />
                            )
                        }
                      </TableCell>
                      <TableCell emphasize>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenant.id}`}>{tenant.name}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenant.id}`}>{tenant.status}</Link>
                      </TableCell>
                      <TableCell>
                        <Link className='hover:text-brand-500 dark:hover:text-brand-400' to={`/admin/organizations/${tenant.id}`}>{tenant.plan}</Link>
                      </TableCell>
                      <TableCell>{tenant.expiration != null ? DateTime.fromJSDate(new Date(tenant.expiration)).toLocaleString(DateTime.DATETIME_SHORT) : ''}</TableCell>
                      <TableCell>{tenant.affiliate2024 != null ? tenant.affiliate2024 + ' (2024) ' : ''}{tenant.affiliate2025 != null ? tenant.affiliate2025 + ' (2025)' : ''}</TableCell>
                      <TableCell>{tenant.owner?.name ?? ''}</TableCell>
                      <TableCell>{tenant.owner?.email ?? ''}</TableCell>
                      <TableActionCell>
                        <div className='flex items-center'>
                          {myRoles.includes(AccountRole.SuperAdministrator) &&
                            <Button Icon={TrashIcon} transparent onClick={() => { doDeleteOrganization(tenant) }} />
                          }
                        </div>
                      </TableActionCell>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingComponent>
          </div>
        </div>
      </div>
    </div>
  )
}
