import { createContext, useState, type PropsWithChildren, type ReactElement, useEffect } from 'react'
import type { ISeason, ITenant, IAccount, IStaffAccount, ISeasonAthleteWithAthlete } from '../../../api/api'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { useGetTenantAccountsQuery, useGetTenantQuery, useGetTenantsQuery } from '../reducers/apiSlice-tenants'
import { useGetSeasonQuery, useGetSeasonsQuery } from '../reducers/apiSlice-seasons'
import { AuthState } from '../reducers/authReducer'
import { useGetMyAccountQuery } from '../reducers/apiSlice'
import { rootLog } from '../logging'
import { useGetAthletesQuery } from '../reducers/apiSlice-athletes'
import { ageGridDivisions, getAgeGridOption, type AgeGridOption } from '../../../api/ageGrids'
import { getPersistedValue, setPersistedValue } from '../store'
import { type SelectOption } from '@andyneville/tailwind-react/SelectBox'

const log = rootLog.child({ module: 'TenantSeasonProvider' })

const defaultAgeGroup: string = 'usasf-eliteclub-2024'
const defaultAgeGroupOption: AgeGridOption = getAgeGridOption(getPersistedValue('ageGrid', defaultAgeGroup))

export interface TenantSeasonContextType {
  me?: IAccount
  tenant?: ITenant
  season?: ISeason
  tenants?: ITenant[]
  seasons?: ISeason[]
  athletes: ISeasonAthleteWithAthlete[]
  staff: IStaffAccount[]
  selectedAgeGridOption: SelectOption
  ageGridOptions: SelectOption[]
  setSelectedAgeGridOption: (option: SelectOption | undefined) => void
  refresh: () => void
}

export const TenantSeasonContext = createContext<TenantSeasonContextType>({
  tenant: undefined,
  season: undefined,
  tenants: [],
  seasons: [],
  staff: [],
  athletes: [],
  selectedAgeGridOption: defaultAgeGroupOption as unknown as SelectOption,
  ageGridOptions: ageGridDivisions as unknown as SelectOption[],
  setSelectedAgeGridOption: () => { },
  refresh: () => { }
})

export default function TenantSeasonProvider (props: PropsWithChildren): ReactElement {
  const { children } = props
  const { currentSeason } = useSelector((state: RootState) => state.season)
  const { authState, currentTenant, tenants: currentTenants, firstName, lastName, email } = useSelector((state: RootState) => state.auth)
  const [lastFetchedTenantId, setLastFetchedTenantId] = useState('')
  const [selectedAgeGridOption, setSelectedAgeGridOption] = useState(defaultAgeGroupOption)

  const doSetSelectedAgeGridOption = (option: SelectOption | undefined): void => {
    if (option == null) {
      return
    }
    setSelectedAgeGridOption(option as unknown as AgeGridOption)
    setPersistedValue('ageGrid', option.id)
  }

  const currentAccount = authState === AuthState.Authenticated ? { firstName, lastName, email } as unknown as IAccount : undefined
  const {
    data: fetchedTenant,
    refetch: refetchTenant
  } = useGetTenantQuery({ tenantId: currentTenant?.id ?? '' }, { skip: authState !== AuthState.Authenticated || currentTenant?.id == null })
  const {
    data: fetchedTenants,
    refetch: refetchTenants
  } = useGetTenantsQuery(undefined, { skip: authState !== AuthState.Authenticated || currentTenant?.id == null })
  const {
    data: fetchedStaff = [],
    refetch: refetchStaff
  } = useGetTenantAccountsQuery({ tenantId: currentTenant?.id ?? '' }, { skip: authState !== AuthState.Authenticated || currentTenant?.id == null })
  const {
    data: fetchedSeason
  } = useGetSeasonQuery({ seasonId: currentSeason?.id ?? '' }, { skip: authState !== AuthState.Authenticated || currentSeason?.id == null })
  const {
    data: fetchedAccount
  } = useGetMyAccountQuery(undefined, { skip: authState !== AuthState.Authenticated })
  const {
    data: seasons = [],
    refetch
  } = useGetSeasonsQuery(undefined, { skip: authState !== AuthState.Authenticated })
  const {
    data: fetchedAthletes = [],
    refetch: refetchAthletes
  } = useGetAthletesQuery({ seasonId: currentSeason?.id ?? '' }, { skip: authState !== AuthState.Authenticated || currentSeason?.id == null })

  const refresh = (): void => {
    log.debug('refreshing in TenantSeasonProvider')
    void refetchTenant()
    void refetchTenants()
    void refetchStaff()
    void refetchAthletes()
  }

  useEffect(() => {
    log.debug('TSP useEffect')
    if (currentTenant != null) {
      if (lastFetchedTenantId === '') {
        log.trace('TSP useEffect 1')
        setLastFetchedTenantId(currentTenant.id)
      } else if (lastFetchedTenantId !== currentTenant.id) {
        log.trace('TSP useEffect 2')
        void refetch()
        setLastFetchedTenantId(currentTenant.id)
      }
    }
  }, [currentTenant, lastFetchedTenantId, refetch])

  const me = (currentAccount != null) ? fetchedAccount ?? currentAccount ?? undefined : undefined
  const tenant = (currentTenant != null) ? fetchedTenant ?? currentTenant ?? undefined : undefined
  const tenants = (currentTenants != null) ? fetchedTenants ?? currentTenants ?? undefined : undefined
  const athletes = (currentSeason != null) ? fetchedAthletes : []
  const season = (currentSeason != null) ? fetchedSeason ?? currentSeason ?? undefined : undefined
  const staff = (currentTenant != null) ? fetchedStaff : []

  log.trace('TSP render', tenant)
  return (
    <TenantSeasonContext.Provider value={{
      me,
      tenant,
      season,
      tenants,
      seasons,
      staff,
      athletes,
      refresh,
      selectedAgeGridOption: selectedAgeGridOption as unknown as SelectOption,
      ageGridOptions: ageGridDivisions as unknown as SelectOption[],
      setSelectedAgeGridOption: doSetSelectedAgeGridOption
    }}>
      {children}
    </TenantSeasonContext.Provider>
  )
}
