import { useEffect, type ReactElement, useState } from 'react'
import { type RootState, useAppDispatch } from '../store'
// import { startOpenIdLogin, completeOpenIdLogin, logout, AuthState, startEmailLogin, startEmailVerify, startEmailSignup } from '@andyneville/garbanzo-lib/browser/authReducer'
import { completeOpenIdLogin, logout, AuthState, startEmailLogin, startEmailVerify, startEmailSignup, getOpenIdLoginUrl, initializeAuthWithRefreshToken } from '../reducers/authReducer'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { SpinnerIcon, Card, Button, Form, TextBox, EmailBox, FormButtons } from '@andyneville/tailwind-react'
import { clear as clearSeason } from '../reducers/seasonReducer'
import googleLoginImageLight from '../assets/google-login-light.svg'
import googleLoginImageDark from '../assets/google-login-dark.svg'
import googleSignupImageLight from '../assets/google-signup-light.svg'
import googleSignupImageDark from '../assets/google-signup-dark.svg'
import Captcha from '../components/Captcha'
import { rootLog } from '../logging'
// import { clear as clearVisits } from '../reducers/visitsReducer'
// import { clear as clearCompany } from '../reducers/companyReducer'
// import { useAddAuthScopeMutation } from '../reducers/apiSlice'
// import googleLoginImageDark from '../assets/google-login-neutral.svg'

const log = rootLog.child({ module: 'AuthRoutes' })

const recaptchaSiteKey = import.meta.env.VITE_RECAPTCHA_SITE_KEY

export function Login (): ReactElement {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const affiliate = searchParams.get('ref')
  const [showEmail, setShowEmail] = useState(false)
  const [showEmailResult, setShowEmailResult] = useState(false)
  const email = useState('')
  const code = useState('')
  const { authState, redirectUrl } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  log.info('Login View')

  useEffect(() => {
    if (authState === AuthState.Authenticated) {
      log.debug('Authenticated in TokenAuth')
      if (redirectUrl != null) {
        let fixedRedirectUrl = redirectUrl
        if (redirectUrl.startsWith(window.location.origin)) {
          fixedRedirectUrl = redirectUrl.substring(window.location.origin.length)
        }
        log.debug('Redirecting to', fixedRedirectUrl)
        navigate(fixedRedirectUrl)
      } else {
        log.debug('Redirecting to /')
        navigate('/')
      }
    }
  }, [authState, redirectUrl, navigate])

  const onEmailStartLogin = async (): Promise<void> => {
    log.debug('Start email login')
    await dispatch(startEmailLogin(email[0], affiliate ?? undefined))
    setShowEmail(false)
    setShowEmailResult(true)
  }

  const onEmailVerifyCode = async (): Promise<void> => {
    log.debug('Start email verify code')
    await dispatch(startEmailVerify(email[0], code[0]))
  }

  const googleLoginUrl = getOpenIdLoginUrl('google', affiliate ?? undefined)

  const signupLink = '/signup' + (affiliate != null ? `?ref=${affiliate}` : '')

  return (
    <div>
      {showEmailResult
        ? (
          <Card title='Verify' subtitle='Please check your email for a verification code' border divider>
            <Form>
              <TextBox label='Verification Code' id='code' binding={code} />
              <FormButtons>
                <Button label='Verify' onClick={() => { void onEmailVerifyCode() }} />
              </FormButtons>
            </Form>
          </Card>
          )
        : showEmail
          ? (
            <>
              <Card title='Login' subtitle='Please enter your email address' border divider>
                <Form>
                  <EmailBox label='Email' id='email' type='email' autoComplete='email' binding={email} />
                  <FormButtons>
                    <Button label='Login' onClick={() => { void onEmailStartLogin() }} />
                  </FormButtons>
                </Form>
              </Card>
              <div className='w-full mt-4 text-lg text-gray-800 dark:text-gray-400'>
                <p>New user? You can <Link to={signupLink} className='text-brand-600 hover:text-brand-500 dark:text-brand-600 dark:hover:text-brand-400'>Sign Up here</Link>.</p>
              </div>
            </>
            )
          : (
            <>
            <Card title='Login' subtitle='Please choose a login option below' border divider>
              { showEmail
                ? (
                <Form>
                  <EmailBox label='Email' id='email' binding={email} />
                  <FormButtons>
                    <Button label='Login' onClick={() => { void onEmailStartLogin() }} />
                  </FormButtons>
                </Form>
                  )
                : (
                  <>
                    <div className='mt-4 text-xs w-full text-center flex justify-center'>
                      <p className='w-48 object-center'>
                        By clicking below, I agree to the CheerSync, LLC <a href='https://cheersyncapp.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://cheersyncapp.com/tos' target='_blank'>Terms of Service</a>.
                      </p>
                    </div>
                    <div className='w-full mt-4 dark:hidden'>
                      <a href={googleLoginUrl}>
                        <img className='m-auto' src={googleLoginImageLight} alt='Login with Google' />
                      </a>
                    </div>
                    <div className='hidden w-full mt-4 dark:block'>
                      <a href={googleLoginUrl}>
                        <img className='m-auto' src={googleLoginImageDark} alt='Login with Google' />
                      </a>
                    </div>
                    <div className='grid w-full mt-4 justify-items-center'>
                      <Button label='Sign in with Email' className='!grid justify-items-center !rounded-full dark:!bg-black !bg-white !border dark:!border-gray-400 !border-gray-500 w-[175px] ' onClick={() => { setShowEmail(true) }} />
                    </div>
                  </>
                  )
                }
              </Card>
              <div className='w-full mt-4 text-lg text-gray-800 dark:text-gray-400'>
                <p>Are you a new user? You can <Link to={signupLink} className='text-brand-600 hover:text-brand-500 dark:text-brand-600 dark:hover:text-brand-400'>Sign Up here</Link>.</p>
              </div>
            </>
            )
      }
    </div>
  )
}

export function Loading (): ReactElement {
  log.info('Loading View')

  return (
    <div>
      <Card title='Login' subtitle='Refreshing login' border divider>
        <SpinnerIcon className="w-10 h-10 spin" />
      </Card>
    </div>
  )
}

export function Signup (): ReactElement {
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const affiliate = searchParams.get('ref')
  const [showEmail, setShowEmail] = useState(false)
  const [showEmailResult, setShowEmailResult] = useState(false)
  const firstName = useState('')
  const lastName = useState('')
  const email = useState('')
  const code = useState('')
  const token = useState<string | null>(null)
  const { authState, redirectUrl } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  log.info('Signup View')

  useEffect(() => {
    if (authState === AuthState.Authenticated) {
      log.debug('Authenticated in TokenAuth')
      if (redirectUrl != null) {
        let fixedRedirectUrl = redirectUrl
        if (redirectUrl.startsWith(window.location.origin)) {
          fixedRedirectUrl = redirectUrl.substring(window.location.origin.length)
        }
        log.debug('Redirecting to', fixedRedirectUrl)
        navigate(fixedRedirectUrl)
      } else {
        log.debug('Redirecting to /')
        navigate('/')
      }
    }
  }, [authState, redirectUrl, navigate])

  const onEmailStartSignup = async (): Promise<void> => {
    log.debug('Start email signup')
    await dispatch(startEmailSignup(email[0], firstName[0], lastName[0], token[0] ?? '', affiliate ?? undefined))
    setShowEmail(false)
    setShowEmailResult(true)
  }

  const onEmailVerifyCode = async (): Promise<void> => {
    log.debug('Start email verify code')
    await dispatch(startEmailVerify(email[0], code[0]))
  }

  const googleLoginUrl = getOpenIdLoginUrl('google', affiliate ?? undefined)
  const loginLink = '/login' + (affiliate != null ? `?ref=${affiliate}` : '')

  return (
    <>
      {showEmailResult
        ? (
          <Card title='Verify' subtitle='Please check your email for a verification code' border divider>
            <Form>
              <TextBox label='Verification Code' id='code' binding={code} />
              <FormButtons>
                <Button label='Verify' onClick={() => { void onEmailVerifyCode() }} />
              </FormButtons>
            </Form>
          </Card >
          )
        : showEmail
          ? (
            <>
              <Card title='Sign Up' subtitle='Please enter your sign up info below' border divider>
                <Form>
                  <TextBox label='First Name' id='firstName' binding={firstName} />
                  <TextBox label='Last Name' id='lastName' binding={lastName} />
                  <EmailBox label='Email' id='email' type='email' autoComplete='email' binding={email} />
                  <Captcha recaptchaSiteKey={recaptchaSiteKey} binding={token} />
                  <FormButtons>
                    <Button label='Sign Up' disabled={token[0] == null} onClick={() => { void onEmailStartSignup() }} />
                  </FormButtons>
                </Form>
              </Card>
              <div className='w-full mt-4 text-lg text-gray-800 dark:text-gray-400'>
                <p>Have an account? You can <Link to={loginLink} className='text-brand-600 hover:text-brand-500 dark:text-brand-600 dark:hover:text-brand-400'>Log In here</Link>.</p>
              </div>
            </>
            )
          : (
          <>
            <Card title='Sign Up' subtitle='Please sign in with one of the options below' border divider>
              <div className='mt-4 text-xs w-full text-center flex justify-center'>
                  <p className='w-48 object-center'>
                  By clicking below, I agree to the CheerSync, LLC <a href='https://cheersyncapp.com/privacy' target='_blank'>Privacy Policy</a> and <a href='https://cheersyncapp.com/tos' target='_blank'>Terms of Service</a>.
                </p>
              </div>
              <div className='w-full mt-4 dark:hidden'>
                <a href={googleLoginUrl}>
                  <img className='m-auto' src={googleSignupImageLight} alt='Sign Up with Google' />
                </a>
              </div>
              <div className='hidden w-full mt-4 dark:block'>
                <a href={googleLoginUrl}>
                  <img className='m-auto' src={googleSignupImageDark} alt='Sign Up with Google' />
                </a>
              </div>
              <div className='grid w-full mt-4 justify-items-center'>
                  <Button label='Sign up with Email' className='!grid justify-items-center !rounded-full dark:!bg-black !bg-white !border dark:!border-gray-400 !border-gray-500 w-[175px]' onClick={() => { setShowEmail(true) }} />
              </div>
            </Card>
            <div className='w-full mt-4 text-lg text-gray-800 dark:text-gray-400'>
              <p>Already have an account? You can <Link to={loginLink} className='text-brand-600 hover:text-brand-500 dark:text-brand-600 dark:hover:text-brand-400'>Log In here</Link>.</p>
            </div>
          </>
            )
    }
    </>
  )
}

const usedCodes: string[] = []

export function TokenAuth (): ReactElement {
  const { providerId = '' } = useParams()

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { authState, redirectUrl } = useSelector((state: RootState) => state.auth)

  const code = searchParams.get('code')
  const state = searchParams.get('state') ?? undefined
  const dispatch = useAppDispatch()

  log.info('TokenAuth View', providerId, code, state)

  useEffect(() => {
    if (authState === AuthState.Authenticated) {
      log.debug('Authenticated in TokenAuth')
      if (redirectUrl != null) {
        let fixedRedirectUrl = redirectUrl
        if (redirectUrl.startsWith(window.location.origin)) {
          fixedRedirectUrl = redirectUrl.substring(window.location.origin.length)
        }
        log.debug('Redirecting to', fixedRedirectUrl)
        navigate(fixedRedirectUrl)
      } else {
        log.debug('Redirecting to /')
        navigate('/')
      }
    } else if (authState === AuthState.Unauthenticated) {
      log.debug('Authenticated in TokenAuth')
      log.debug('Redirecting to /login')
      navigate('/login')
    }
  }, [authState, redirectUrl, navigate])

  useEffect(() => {
    if (code !== null && code.length > 0) {
      if (usedCodes.includes(code)) {
        return
      }
      usedCodes.push(code)
      log.debug('token auth with code ' + code)
      void dispatch(completeOpenIdLogin(code, providerId, state)).finally(() => {
        log.debug('completeOpenIdLogin completed in TokenAuth')
      })
    }
  }, [code, providerId, state, dispatch])

  return (
    <>
      <div>
        <SpinnerIcon className="w-10 h-10 spin" />
        <div className='mt-4'>Authenticating</div>
      </div>
    </>
  )
}

export function RefreshToken (): ReactElement {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { authState, redirectUrl } = useSelector((state: RootState) => state.auth)

  const refreshToken = searchParams.get('refreshToken')
  const dispatch = useAppDispatch()

  log.info('RefreshToken View', refreshToken)

  useEffect(() => {
    if (authState === AuthState.Authenticated) {
      log.debug('Authenticated in TokenAuth')
      if (redirectUrl != null) {
        let fixedRedirectUrl = redirectUrl
        if (redirectUrl.startsWith(window.location.origin)) {
          fixedRedirectUrl = redirectUrl.substring(window.location.origin.length)
        }
        log.debug('Redirecting to', fixedRedirectUrl)
        navigate(fixedRedirectUrl)
      } else {
        log.debug('Redirecting to /')
        navigate('/')
      }
    } else if (authState === AuthState.Unauthenticated) {
      log.debug('Authenticated in TokenAuth')
      log.debug('Redirecting to /login')
      navigate('/login')
    }
  }, [authState, redirectUrl, navigate])

  useEffect(() => {
    if (refreshToken !== null && refreshToken.length > 0) {
      log.debug('refreshToken auth with token ' + refreshToken)
      void dispatch(initializeAuthWithRefreshToken(refreshToken)).finally(() => {
        log.debug('initializeAuthWithRefreshToken completed in RefreshTokenAuth')
      })
    }
  }, [refreshToken, dispatch])

  return (
    <>
      <div>
        <SpinnerIcon className="w-10 h-10 spin" />
        <div className='mt-4'>Authenticating</div>
      </div>
    </>
  )
}

export function Logout (): ReactElement {
  log.info('Logout View')
  const { authState } = useSelector((state: RootState) => state.auth)
  if (authState === AuthState.Unauthenticated) {
    return Login()
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch()
  // const navigate = useNavigate()

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    void dispatch(logout()).finally(() => {
      log.debug('Logout View dispatch completed')
    })
    dispatch(clearSeason())
  }, [authState, dispatch])

  return (
    <>
      <div>
        <SpinnerIcon className="w-10 h-10 spin" />
        <div className='mt-4'>Logging out</div>
      </div>
    </>
  )
}

export function LoginError (): ReactElement {
  log.info('LoginError View')

  return (
    <>
      <div>
        <ExclamationTriangleIcon className="w-10 h-10" />
        <div className='mt-4'>Error logging in</div>
      </div>
    </>
  )
}

export function SelfRegistrationDisabled (): ReactElement {
  log.info('SelfRegistrationDisabled View')

  return (
    <>
      <div>
        <ExclamationTriangleIcon className="w-10 h-10" />
        <div className='mt-4'>Self registration is currently disabled while we finalize testing, but we have added you to our waitlist and will notify you as soon as we are ready to open up for everyone!</div>
      </div>
    </>
  )
}
