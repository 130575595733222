import { useState, useContext, type ReactElement } from 'react'
import { type TryoutSkill, type SkillLevel, type TryoutType, skillLevels } from '../../../api/api'
import { Button, ContainerItem, GlobalDialogContext, TextBox } from '@andyneville/tailwind-react'
import { CheckIcon, PencilIcon, PlusIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'

export interface CustomSkillProps {
  tryoutType: TryoutType
  customSkills: Record<SkillLevel, TryoutSkill[]>
  addCustomSkill: (type: TryoutType, level: SkillLevel) => void
  removeCustomSkill: (type: TryoutType, level: SkillLevel, index: number) => void
  setCustomSkill: (type: TryoutType, level: SkillLevel, index: number, value: string) => void
  removeTryoutType: (type: TryoutType) => void
  renameTryoutType: (oldType: TryoutType, newType: TryoutType) => void
}

export function CustomSkill (props: CustomSkillProps): ReactElement {
  const {
    tryoutType,
    customSkills,
    addCustomSkill,
    removeCustomSkill,
    setCustomSkill,
    removeTryoutType,
    renameTryoutType
  } = props

  const [editName, setEditName] = useState(false)
  const [name, setName] = useState('')
  const globalDialog = useContext(GlobalDialogContext)

  const promptRemoveTryoutType = (): void => {
    globalDialog.showQuestion(
      'Remove Tryout Type',
      `Are you sure you want to remove the tryout type "${tryoutType}"?`,
      'Remove',
      () => { removeTryoutType(tryoutType) }
    )
  }

  return (
    <div>
      {editName
        ? (
          <div className='flex items-center'>
            <TextBox value={name} onChange={setName} />
            <Button className='sm:col-span-2' Icon={CheckIcon} transparent onClick={() => { renameTryoutType(tryoutType, name as TryoutType); setEditName(false) }} />
            <Button className='sm:col-span-2' Icon={XMarkIcon} transparent onClick={() => { setName(name); setEditName(false) }} />
          </div>
          )
        : (
          <div className='flex items-center'>
            <h4 className='text-lg'>{tryoutType}</h4>
            <Button className='sm:col-span-2 pl-4 pr-1' Icon={PencilIcon} transparent onClick={() => { setName(tryoutType); setEditName(true) }} />
            <Button className='sm:col-span-2 pl-0' Icon={TrashIcon} transparent onClick={() => { promptRemoveTryoutType() }} />
          </div>
          )}
      {skillLevels.map((level) => (
        <div key={level}>
          <div className='font-bold'>Level {level.substring(1)}</div>
          <ul>
            {(customSkills[level] ?? []).map((skill, idx) => (
              <div key={idx} className='grid gap-2 sm:grid-cols-12'>
                <TextBox columns={10} value={skill} updateOnBlur onChange={(value) => { setCustomSkill(tryoutType, level, idx, value) }} />
                <Button className='sm:col-span-2' Icon={TrashIcon} transparent onClick={() => { removeCustomSkill(tryoutType, level, idx) }} />
              </div>
            ))}
          </ul>
          <Button className='' Icon={PlusIcon} transparent onClick={() => { addCustomSkill(tryoutType, level) }} />
        </div>
      ))}
    </div>
  )
}

export interface CustomSkillsProps {
  customSkills: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
  addCustomSkill: (type: TryoutType, level: SkillLevel) => void
  removeCustomSkill: (type: TryoutType, level: SkillLevel, index: number) => void
  setCustomSkill: (type: TryoutType, level: SkillLevel, index: number, value: string) => void
  removeTryoutType: (type: TryoutType) => void
  renameTryoutType: (oldType: TryoutType, newType: TryoutType) => void
}

export default function CustomSkills (props: CustomSkillsProps): ReactElement {
  const {
    customSkills,
    addCustomSkill,
    removeCustomSkill,
    setCustomSkill,
    removeTryoutType,
    renameTryoutType
  } = props

  return (
    <ContainerItem className='mt-4' columns={12}>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        {(Object.keys(customSkills) as TryoutType[]).map(tryoutType => (
          <CustomSkill
            key={tryoutType}
            tryoutType={tryoutType}
            customSkills={customSkills[tryoutType]}
            addCustomSkill={addCustomSkill}
            removeCustomSkill={removeCustomSkill}
            setCustomSkill={setCustomSkill}
            removeTryoutType={removeTryoutType}
            renameTryoutType={renameTryoutType}
            />
        ))}
      </div>
    </ContainerItem>
  )
}

/*
          <div>
            <h4 className='text-lg'>{tryoutType}</h4>
            {skillLevels.map((level) => (
              <div key={level}>
                <div className='font-bold'>Level {level.substring(1)}</div>
                <ul>
                  {(customSkills[tryoutType][level] ?? []).map((skill, idx) => (
                    <div key={idx} className='grid gap-2 sm:grid-cols-12'>
                      <TextBox columns={10} value={skill} updateOnBlur onChange={(value) => { setCustomSkill(tryoutType, level, idx, value) }} />
                      <Button className='sm:col-span-2' Icon={TrashIcon} transparent onClick={() => { removeCustomSkill(tryoutType, level, idx) }} />
                    </div>
                  ))}
                </ul>
                <Button className='' Icon={PlusIcon} transparent onClick={() => { addCustomSkill(tryoutType, level) }} />
              </div>
            ))}
          </div>

*/
