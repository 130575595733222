import { useMemo, useState, type ReactElement } from 'react'
import { Button, Dialog, Form, RadioButtons, TextBox } from '@andyneville/tailwind-react'
import { type SkillLevel, type TryoutSkill, type TryoutType, type ITryout, skillLevelValues } from '../../../api/api'

export interface AddTryoutDialogProps {
  showDialog: boolean
  setShowDialog: (show: boolean) => void
  addTryout: (tryout: Partial<ITryout>) => void
  skillDefinition: Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>>
}

export default function EditTryoutDialog (props: AddTryoutDialogProps): ReactElement {
  const {
    showDialog,
    setShowDialog,
    addTryout,
    skillDefinition
  } = props

  const [addTryoutType, setAddTryoutType] = useState<string>('')
  const [addTryoutLevel, setAddTryoutLevel] = useState<string>('')
  const [addTryoutSkill, setAddTryoutSkill] = useState<string>('')
  const [addTryoutCustomSkill, setAddTryoutCustomSkill] = useState<string>('')
  const [useCustomTryoutSkill, setUseCustomTryoutSkill] = useState(false)

  const allTryoutTypes = useMemo(() => {
    const result: Record<string, string> = {}
    for (const type in skillDefinition) {
      result[type] = type
    }

    return result
  }, [skillDefinition])

  const allTryoutSkills = useMemo(() => {
    const result: Record<string, string> = {}
    if (addTryoutType !== '' && addTryoutLevel !== '' && skillDefinition[addTryoutType as TryoutType]?.[addTryoutLevel as SkillLevel] != null) {
      for (const skill in skillDefinition[addTryoutType as TryoutType][addTryoutLevel as SkillLevel]) {
        result[skill] = skill
      }
    }
    return result
  }, [addTryoutType, addTryoutLevel, skillDefinition])

  const doAddTryout = (): void => {
    const newTryout: Partial<ITryout> = {
      type: addTryoutType as TryoutType,
      level: addTryoutLevel as SkillLevel,
      skill: addTryoutSkill as TryoutSkill
    }
    addTryout(newTryout)
    setAddTryoutType('')
    setAddTryoutLevel('')
    setAddTryoutSkill('')
    setUseCustomTryoutSkill(false)
    setAddTryoutCustomSkill('')
    setShowDialog(false)
  }

  const doSetAddTryoutLevel = (level: string): void => {
    setAddTryoutLevel(level)
    setUseCustomTryoutSkill(false)
  }

  const doSetAddTryoutSkill = (skill: string): void => {
    if (skill === 'Custom') {
      setUseCustomTryoutSkill(true)
    } else {
      setUseCustomTryoutSkill(false)
    }
    setAddTryoutSkill(skill)
  }

  return (
    <Dialog title='Add Tryout' open={showDialog} onClose={() => { setShowDialog(false) }} buttons={
      <>
        <Button label='Save' disabled={addTryoutType === '' || addTryoutLevel === '' || addTryoutSkill === ''} primary onClick={() => { doAddTryout() }} />
        <Button label='Cancel' onClick={() => { setShowDialog(false) }} />
      </>
    }>
      <Form>
        <RadioButtons label='Tryout Type' columns={12} value={addTryoutType} onChange={(value) => { setAddTryoutType(value); setAddTryoutLevel(''); setAddTryoutSkill('') }} options={allTryoutTypes} />
        {addTryoutType !== '' &&
          <RadioButtons label='Level' columns={12} value={addTryoutLevel} onChange={(value) => { doSetAddTryoutLevel(value); setAddTryoutSkill('') }} options={skillLevelValues} />
        }
        {addTryoutType !== '' && addTryoutLevel !== '' &&
          <RadioButtons label='Skill' columns={12} value={addTryoutSkill} onChange={(value) => { doSetAddTryoutSkill(value) }} options={allTryoutSkills} />
        }
        {addTryoutType !== '' && addTryoutLevel !== '' && useCustomTryoutSkill &&
          <TextBox label='Custom Skill' columns={12} value={addTryoutCustomSkill} onChange={setAddTryoutCustomSkill} />
        }
      </Form>
    </Dialog>
  )
}
