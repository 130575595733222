import { Button, Dialog, Form, TextBox, Mask, classes } from '@andyneville/tailwind-react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { useContext, useMemo, useState, type ReactElement } from 'react'
import { GoogleSheetImportError, type IGoogleSheetImportPreview, type IGoogleSheetImportPreviewError, type ISeasonAthleteWithAthlete } from '../../../api/api'
import { useDoImportMutation, usePreviewImportMutation } from '../reducers/apiSlice-seasons'
import { useSelector } from 'react-redux'
import AthleteCard from './AthleteCard'
import { type RootState } from '../store'
import { rootLog } from '../logging'
import { ageGroupsFromBirthYear } from '../../../api/ageGrids'
import { TenantSeasonContext } from './TenantSeasonProvider'

const log = rootLog.child({ module: 'ImportDialog' })

export interface UnboundImportDialogProps {
  previewAthletes?: ISeasonAthleteWithAthlete[]
  open: boolean
  canImport: boolean
  isImporting: boolean
  isPreviewing: boolean
  error?: GoogleSheetImportError
  onClose: (updated?: boolean) => void
  onPreview?: (url: string) => void
  onImport?: (url: string) => void
  importComplete?: boolean
}

function errorDescription (error: GoogleSheetImportError): string {
  switch (error) {
    case GoogleSheetImportError.InvalidUrl:
      return 'An invalid URL was provided. Please provide a valid Google Sheets URL.'
    case GoogleSheetImportError.InvalidSheet:
      return 'The Google Sheets document is invalid. Please ensure the document is shared with anyone with the link.'
    case GoogleSheetImportError.InvalidHeaders:
      return 'The Google Sheets document is invalid. Please ensure the document has a header row with at least these three required headers: firstName, lastName, birthYear'
    case GoogleSheetImportError.NoAccess:
      return 'The Google Sheets document is not accessible. Please ensure the document is shared so that anyone with the link can view it.'
    case GoogleSheetImportError.NoAthletes:
      return 'The Google Sheets document does not contain any athletes.'
    case GoogleSheetImportError.NoHeaders:
      return 'The Google Sheets document does not contain any headers. Please ensure the document has the correct header row.'
    case GoogleSheetImportError.Internal:
      return 'An internal error occurred. Please try again later.'
    default:
      return 'An unknown error occurred.'
  }
}

export function UnboundImportDialog (props: UnboundImportDialogProps): ReactElement {
  const { open, onClose, previewAthletes = [], error, onPreview, onImport, canImport, isPreviewing, isImporting, importComplete } = props
  const [sheetUrl, setSheetUrl] = useState('')

  const googleSheetUrlRegex = /https:\/\/docs.google.com\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/

  const doPreview = (): void => {
    const match = googleSheetUrlRegex.exec(sheetUrl)
    log.debug('match', match)
    const sheetId = match?.[1]
    if (onPreview != null && sheetId != null) {
      onPreview(sheetId)
    }
  }

  const doImport = (): void => {
    const match = googleSheetUrlRegex.exec(sheetUrl)
    log.debug('match', match)
    const sheetId = match?.[1]
    if (onImport != null && sheetId != null && canImport) {
      onImport(sheetId)
    }
  }

  const isValidSheetUrl = (): boolean => googleSheetUrlRegex.test(sheetUrl)

  return (
    <Dialog title='Import Athletes' Icon={ArrowDownTrayIcon} open={open} onClose={onClose} buttons={
      <>
        {importComplete !== true &&
          <Button label='Preview' primary={!canImport} onClick={doPreview} disabled={!isValidSheetUrl()} />
        }
        {importComplete !== true && canImport &&
          <Button label='Import' primary onClick={doImport} />
        }
        {importComplete !== true &&
          <Button label='Cancel' onClick={onClose} />
        }
        {importComplete === true &&
          <Button label='Close' onClick={() => { log.debug('click close', importComplete); onClose(importComplete) }} />
        }
      </>
    }>
      <Mask show={isPreviewing || isImporting} message={isPreviewing ? 'Previewing data...' : 'Importing...'}>
        <Form className='py-2'>
          <TextBox columns={12} label='Google Sheets URL' value={sheetUrl} onChange={setSheetUrl} invalid={sheetUrl.length > 0 && !isValidSheetUrl()} />
          <div className={classes('mt-4 sm:col-span-12', importComplete === true ? 'opacity-50' : '')}>
            Paste a link to a Google Sheets document which is shared with permissions for anyone to
            view. <a target='_blank' rel="noreferrer nofollow" href='https://docs.google.com/spreadsheets/d/1wMBHDzkbftVHzYeY1LkuKdBl12FbCS7d3qUXEYE9RpA/copy'>Click here</a> to open a template you can use for your own roster.
          </div>
        </Form>
        {importComplete !== true && previewAthletes.length > 0 &&
          <ul role="list" className="grid grid-cols-1 gap-6 ">
            {previewAthletes.slice(0, 3).map((athlete, athleteIdx) => (
              <AthleteCard
                key={athleteIdx}
                athlete={athlete}
                preview
              />
            ))}
          </ul>
        }
        {error != null &&
          <div className='text-red-600 dark:text-red-500'>{errorDescription(error)}</div>
        }
        {importComplete === true &&
          <div className='text-green-600 dark:text-green-500 text-lg'>Import Complete</div>
        }
      </Mask>
    </Dialog>
  )
}

export interface ImportDialogProps {
  open: boolean
  onClose: (updated: boolean) => void
}

const emptyData = {}
const emptyArray: ISeasonAthleteWithAthlete[] = []

export default function ImportDialog (props: ImportDialogProps): ReactElement {
  const { currentSeasonId } = useSelector((state: RootState) => state.season)
  const { selectedAgeGridOption } = useContext(TenantSeasonContext)
  const { open, onClose } = props
  const [importComplete, setImportComplete] = useState(false)
  const [
    previewImport,
    {
      data: importPreview = emptyData,
      isSuccess: isPreviewSuccess,
      isLoading: isPreviewFetching
    }
  ] = usePreviewImportMutation()
  const [
    doImport,
    {
      data: importResult = emptyData,
      isSuccess: isImportSuccess,
      isError: isImportError,
      isLoading: isImportLoading
    }
  ] = useDoImportMutation()

  const previewError = ((importPreview as IGoogleSheetImportPreviewError).error != null ? (importPreview as IGoogleSheetImportPreviewError).error : undefined)
  const importError = ((importPreview as IGoogleSheetImportPreviewError).error != null ? (importResult as IGoogleSheetImportPreviewError).error : undefined)

  const athletes = useMemo(() => {
    const athletes = (importPreview as IGoogleSheetImportPreview)?.athletes ?? emptyArray
    return athletes.map((athlete) => Object.assign({}, athlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id, athlete.birthYear) }))
  }, [importPreview, selectedAgeGridOption])

  const onPreview = (sheetId: string): void => {
    log.debug('doPreview', sheetId)
    void previewImport({ sheetId })
  }

  const onImport = (sheetId: string): void => {
    if (currentSeasonId == null) {
      log.debug('currentSeasonId is null')
      return
    }
    log.debug('doImport', sheetId)
    void doImport({ seasonId: currentSeasonId, sheetId })
  }

  if (isImportSuccess && !isImportError && !isImportLoading && !importComplete && (importResult as IGoogleSheetImportPreview)?.athletes != null && (importResult as IGoogleSheetImportPreview)?.athletes.length >= 1) {
    log.debug('import success')
    setImportComplete(true)
    if (onClose != null) {
      // onClose(true)
    }
  }

  const canImport = isPreviewSuccess && !isPreviewFetching && (importPreview as IGoogleSheetImportPreview)?.athletes?.length != null && (importPreview as IGoogleSheetImportPreview).athletes.length > 0

  return (
    <UnboundImportDialog
      open={open}
      importComplete={importComplete}
      onClose={(updated) => { setImportComplete(false); onClose(updated ?? false) }}
      onImport={onImport}
      error={previewError ?? importError}
      isPreviewing={isPreviewFetching}
      isImporting={isImportLoading}
      canImport={canImport}
      previewAthletes={athletes}
      onPreview={onPreview}
    />
  )
}
