import { type ReactElement, useContext, useState, useRef, useEffect } from 'react'

import { Button, Card, ContainerItem, Form, FormButtons, ImageFileDropButton, LoadingComponent, TextBox } from '@andyneville/tailwind-react'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { type IMyAccountInput, type ITenant, skillLevels, skillsByTypeLevel, type TryoutType, type SkillLevel, type TryoutSkill } from '../../../api/api'
import { useAddLogoMutation, useCompleteOnboardingMutation, useEditTenantMutation } from '../reducers/apiSlice-tenants'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

import unknownLogo from '../assets/logo2.png'
import { useEditMyAccountMutation } from '../reducers/apiSlice'
import ProgressBullets from '../components/ProgressBullets'
import ToggleButton from '../components/ToggleButton'
import { useNavigate } from 'react-router'
import { rootLog } from '../logging'
import CustomSkills from '../components/CustomSkills'

const log = rootLog.child({ module: 'Onboarding' })

function copySkills (input: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>): Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> {
  const result: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> = {} as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
  for (const type of Object.keys(input) as TryoutType[]) {
    result[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
    for (const level of Object.keys(input[type]) as SkillLevel[]) {
      result[type][level] = [...input[type][level]]
    }
  }
  return result
}

function cleanSkills (input: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>): Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> {
  const result: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> = {} as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
  for (const type of Object.keys(input) as TryoutType[]) {
    result[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
    for (const level of Object.keys(input[type]) as SkillLevel[]) {
      result[type][level] = [...input[type][level]].filter((skill) => skill.trim() !== '')
    }
  }
  return result
}

export default function Onboarding (): ReactElement {
  const { me, tenant } = useContext(TenantSeasonContext)
  const [editTenant] = useEditTenantMutation()
  const [editMyAccount] = useEditMyAccountMutation()
  const [addLogo] = useAddLogoMutation()
  const [completeOnboarding] = useCompleteOnboardingMutation()
  const [showStep, setShowStep] = useState(1)
  const firstNameRef = useRef<HTMLInputElement>(null)
  const orgNameRef = useRef<HTMLInputElement>(null)
  const [useCustomSkills, setUseCustomSkills] = useState(false)
  const [customSkills, setCustomSkills] = useState<Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>>(skillsByTypeLevel as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>)
  const navigate = useNavigate()

  useEffect(() => {
    if (tenant != null) {
      if (tenant.settings?.skills != null) {
        setCustomSkills(tenant.settings.skills)
        setUseCustomSkills(true)
      }
    }
  }, [tenant])

  const doUpdateMe = async (value: Partial<IMyAccountInput>): Promise<void> => {
    if (tenant != null) {
      await editMyAccount({
        ...value
      })
    }
  }

  const doUpdateTenant = async (value: Partial<ITenant>): Promise<void> => {
    if (tenant != null) {
      await editTenant({
        id: tenant.id,
        ...value
      })
    }
  }

  const onDropLogo = async (file: File): Promise<void> => {
    if (tenant == null) {
      log.debug('onDropLogo: tenant is null')
      return
    }
    await addLogo({ tenantId: tenant.id, file })
  }

  const setCustomSkill = (type: TryoutType, level: SkillLevel, index: number, value: string): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[type][level][index] = value as TryoutSkill
      return newSkills
    })
  }

  const addCustomSkill = (type: TryoutType, level: SkillLevel): void => {
    log.debug('addCustomSkill', type, level)
    const newSkill = '' as TryoutSkill
    setCustomSkills((prev) => {
      log.debug('prev', prev)
      const newSkills = copySkills(prev)
      log.debug('new', newSkills)
      if (newSkills[type] == null) {
        newSkills[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
      }
      if (newSkills[type][level] == null) {
        newSkills[type][level] = [] as unknown as TryoutSkill[]
      }
      newSkills[type][level].push(newSkill) // = [...prev[type][level], newSkill]
      log.debug('final', newSkills)
      return newSkills
    })
  }

  const removeCustomSkill = (type: TryoutType, level: SkillLevel, index: number): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[type][level].splice(index, 1) // = prev[type][level].filter((_, idx) => idx !== index)
      return newSkills
    })
  }

  const addTryoutType = (): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills['New Custom Type' as TryoutType] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
      return newSkills
    })
  }

  const removeTryoutType = (type: TryoutType): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      delete newSkills[type] // eslint-disable-line @typescript-eslint/no-dynamic-delete
      return newSkills
    })
  }

  const renameTryoutType = (oldType: TryoutType, newType: TryoutType): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[newType] = newSkills[oldType]
      delete newSkills[oldType] // eslint-disable-line @typescript-eslint/no-dynamic-delete
      return newSkills
    })
  }

  const onFinish = async (): Promise<void> => {
    if (tenant != null) {
      log.debug('finishing skills')
      if (useCustomSkills) {
        const cleanedSkills = cleanSkills(customSkills)
        await editTenant({
          id: tenant.id,
          settings: { ...tenant.settings, skills: cleanedSkills }
        })
      } else {
        await editTenant({
          id: tenant.id,
          settings: { ...tenant.settings, skills: undefined }
        })
      }
      // if (tenant.plan !== TenantPlan.New && tenant.expiration != null && new Date(tenant.expiration) > new Date()) {
      await completeOnboarding({ tenantId: tenant.id })
      navigate('/')
      // } else {
      //   navigate('/plans')
      // }
    }
  }

  return (
    <>
      <LoadingComponent isLoading={me == null || tenant == null}>
        <div className='flex flex-col items-center'>
          <div className=''>
            <h1 className='text-3xl text-center md:mt-8'>Welcome to CheerSync!</h1>
            <ProgressBullets current={showStep} total={3} className='mt-4' onClick={(step) => { setShowStep(step) }} />
            {showStep === 1 &&
              <>
                <div className='mt-8 text-xl'>
                  Let's get started! Please fill out the following information to start setting up your account.
                </div>

                <Card title='Account Settings' divider className='mt-4'>
                  <Form className='gap-4 mt-4' autofocus={firstNameRef}>
                    <TextBox ref={firstNameRef} columns={12} label='First Name' value={me?.firstName} updateOnBlur onChange={(value) => { void doUpdateMe({ firstName: value }) }} />
                    <TextBox columns={12} label='Last Name' value={me?.lastName} updateOnBlur onChange={(value) => { void doUpdateMe({ lastName: value }) }} />
                    <FormButtons>
                      <Button label='Next' primary onClick={() => { setShowStep(2) }} />
                    </FormButtons>
                  </Form>
                </Card>
              </>
            }
            {showStep === 2 &&
              <>
                <div className='mt-8 text-xl'>
                  Next, please fill in the name of your organization and choose to upload your logo if you like.
                </div>
                <Card title='Organization Settings' divider className='mt-4'>
                  <Form className='mt-4 gap-y-8' autofocus={orgNameRef}>
                    <TextBox ref={orgNameRef} label='Organization Name' value={tenant?.name} updateOnBlur onChange={(value) => { void doUpdateTenant({ name: value }) }} />
                    <ContainerItem columns={12}>
                      <label>Upload a Logo <i>(optional)</i></label>
                      <ImageFileDropButton
                        url={tenant?.logo}
                        defaultUrl={unknownLogo}
                        onDrop={onDropLogo}
                        className='w-32 h-32 rounded-lg md:w-40 md:h-40 lg:w-48 lg:h-48'
                        imageClassName='w-32 h-32 rounded-lg md:w-40 md:h-40 lg:w-48 lg:h-48'
                        AcceptIcon={ArrowDownTrayIcon}
                      />
                    </ContainerItem>
                    <FormButtons>
                      <Button label='Back' onClick={() => { setShowStep(1) }} />
                      <Button label='Next' primary onClick={() => { setShowStep(3) }} />
                    </FormButtons>
                    </Form>
                </Card>
              </>
            }
            {showStep === 3 &&
              <>
                <div className='mt-8 text-xl'>
                Finally, choose whether you would like to use the standard tryout skills, or choose your own defaults.
                You can always change these later.
                </div>

                <Card title='Skills' divider className='mt-4'>
                  <Form className='mt-4 gap-y-4' autofocus={orgNameRef}>
                    <ToggleButton columns={12} leftLabel='Default skills' rightLabel='Custom skills' value={useCustomSkills} onChange={setUseCustomSkills} />
                    {!useCustomSkills &&
                    <ContainerItem className='mt-4' columns={12}>
                      <div className='grid grid-cols-2 gap-4'>
                        {(Object.keys(skillsByTypeLevel) as TryoutType[]).map(tryoutType => (
                          <div>
                            <h4 className='text-base'>{tryoutType}</h4>
                            {skillLevels.map((level, idx) => (
                              <div key={level}>
                                <div className='font-bold'>Level {idx + 1}</div>
                                <ul>
                                  {(skillsByTypeLevel as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>)[tryoutType][level].map((skill) => (
                                    <li key={skill} className='ml-2'>{skill}</li>
                                  ))}
                                </ul>
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    </ContainerItem>
                    }
                    {useCustomSkills &&
                    <CustomSkills customSkills={customSkills} addCustomSkill={addCustomSkill} removeCustomSkill={removeCustomSkill} setCustomSkill={setCustomSkill} renameTryoutType={renameTryoutType} removeTryoutType={removeTryoutType} />
                    }
                    <FormButtons>
                      <Button label='Add New Custom Type' onClick={() => { addTryoutType() }} />
                      <Button label='Back' onClick={() => { setShowStep(2) }} />
                      <Button label='Finish' primary onClick={() => { void onFinish() }} />
                    </FormButtons>
                  </Form>
                </Card>
              </>
            }
          </div>
        </div>
      </LoadingComponent>
    </>
  )
}
