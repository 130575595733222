import { useEffect, useMemo, useState, type ReactElement } from 'react'
import { Button, Dialog, Form, RadioButtons, TextBox } from '@andyneville/tailwind-react'
import { type SkillLevel, type TryoutSkill, type TryoutType, type ITryout, skillLevelValues } from '../../../api/api'

const nullTryout: ITryout = {} as unknown as ITryout

export interface EditTryoutDialogProps {
  showDialog: boolean
  setShowDialog: (show: boolean) => void
  tryout: ITryout | null
  saveTryout: (tryout: ITryout) => void
  skillDefinition: Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>>
}

export default function EditTryoutDialog (props: EditTryoutDialogProps): ReactElement {
  const {
    showDialog,
    setShowDialog,
    tryout: inputTryout,
    saveTryout,
    skillDefinition
  } = props

  const tryout = inputTryout ?? nullTryout

  const [addTryoutType, setAddTryoutType] = useState<string>(tryout.type ?? '')
  const [addTryoutLevel, setAddTryoutLevel] = useState<string>(tryout.level ?? '')
  const [addTryoutSkill, setAddTryoutSkill] = useState<string>(tryout.skill ?? '')
  const [addTryoutCustomSkill, setAddTryoutCustomSkill] = useState<string>('')
  const [useCustomTryoutSkill, setUseCustomTryoutSkill] = useState(false)

  useEffect(() => {
    setAddTryoutType(tryout.type ?? '')
    setAddTryoutLevel(tryout.level ?? '')
    setAddTryoutSkill(tryout.skill ?? '')
  }, [tryout])

  const allTryoutTypes = useMemo(() => {
    const result: Record<string, string> = {}
    for (const type in skillDefinition) {
      result[type] = type
    }
    if (tryout.type != null && (tryout.type as string) !== '' && result[tryout.type] == null) {
      result[tryout.type] = tryout.type
    }
    return result
  }, [skillDefinition, tryout.type])

  const allTryoutSkills = useMemo(() => {
    const result: Record<string, string> = {}
    if (addTryoutType !== '' && addTryoutLevel !== '' && skillDefinition[addTryoutType as TryoutType]?.[addTryoutLevel as SkillLevel] != null) {
      for (const skill in skillDefinition[addTryoutType as TryoutType][addTryoutLevel as SkillLevel]) {
        result[skill] = skill
      }
    }
    if (tryout.skill != null && (tryout.skill as string) !== '' && result[tryout.skill] == null) {
      result[tryout.skill] = tryout.skill
    }
    return result
  }, [addTryoutType, addTryoutLevel, skillDefinition, tryout])

  const doSaveTryout = (): void => {
    const newTryout: ITryout = {
      ...tryout,
      type: addTryoutType as TryoutType,
      level: addTryoutLevel as SkillLevel,
      skill: addTryoutSkill as TryoutSkill
    }
    saveTryout(newTryout)
    setShowDialog(false)
  }

  const doSetAddTryoutLevel = (level: string): void => {
    setAddTryoutLevel(level)
    setUseCustomTryoutSkill(false)
  }

  const doSetAddTryoutSkill = (skill: string): void => {
    if (skill === 'Custom') {
      setUseCustomTryoutSkill(true)
    } else {
      setUseCustomTryoutSkill(false)
    }
    setAddTryoutSkill(skill)
  }

  if (tryout === nullTryout) {
    return <></>
  }

  return (
    <Dialog title='Edit Tryout' open={showDialog} onClose={() => { setShowDialog(false) }} buttons={
      <>
        <Button label='Save' disabled={addTryoutType === '' || addTryoutLevel === '' || addTryoutSkill === ''} primary onClick={() => { doSaveTryout() }} />
        <Button label='Cancel' onClick={() => { setShowDialog(false) }} />
      </>
    }>
      <Form>
        <RadioButtons label='Tryout Type' columns={12} value={addTryoutType} onChange={(value) => { setAddTryoutType(value); setAddTryoutLevel(''); setAddTryoutSkill('') }} options={allTryoutTypes} />
        {addTryoutType !== '' &&
          <RadioButtons label='Level' columns={12} value={addTryoutLevel} onChange={(value) => { doSetAddTryoutLevel(value); setAddTryoutSkill('') }} options={skillLevelValues} />
        }
        {addTryoutType !== '' && addTryoutLevel !== '' &&
          <RadioButtons label='Skill' columns={12} value={addTryoutSkill} onChange={(value) => { doSetAddTryoutSkill(value) }} options={allTryoutSkills} />
        }
        {addTryoutType !== '' && addTryoutLevel !== '' && useCustomTryoutSkill &&
          <TextBox label='Custom Skill' columns={12} value={addTryoutCustomSkill} onChange={setAddTryoutCustomSkill} />
        }
      </Form>
    </Dialog>
  )
}
