import { type ReactElement, useContext, useEffect, useState, useMemo } from 'react'
import { GlobalDialogContext, Button, Container, ContainerItem, Form, ImageFileDropButton, PageHeader, TextArea, TextBox, classes } from '@andyneville/tailwind-react'
import ParentIcon from '../components/icons/Parent'

import { ArrowUpTrayIcon, CameraIcon, CloudArrowUpIcon, NoSymbolIcon, PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import Rating from '../components/Rating'
import RecordButton from '../components/RecordButton'
import RecordVideoDialog from '../components/RecordVideoDialog2'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteAthleteMutation, useEditAthleteMutation, useGetAthleteQuery } from '../reducers/apiSlice-athletes'
import { type ISeasonAthleteWithAthleteInput, type ITryout, Position, type SkillLevel, type TryoutSkill, AgeGroup, TryoutType, skillsByTypeLevel, skillLevels, type ISeasonAthleteWithAthlete } from '../../../api/api'
import { useLiveQuery } from 'dexie-react-hooks'
import unknownUser from '../assets/user.png'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { uploadVideoFile, useAddTryoutMutation, useAddTryoutVideoSyncMutation, useDeleteTryoutMutation, useEditTryoutMutation, useGetTryoutsQuery } from '../reducers/apiSlice-tryouts'
import PlayVideoDialog from '../components/PlayVideoDialog'
import VideoPreview from '../components/VideoPreview'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { rootLog } from '../logging'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { type Accept } from 'react-dropzone'
import { db, UploadStatus } from '../db'
import LocalVideoPreview from '../components/LocalVideoPreview'
import LocalPlayVideoDialog from '../components/LocalPlayVideoDialog'
import { ClientSyncContext } from '../components/ClientSyncProvider'
import { rtkErrorToString } from '../util'
import { ageGroupsFromBirthYear, birthYearRequiresMidYearRange, allLevels } from '../../../api/ageGrids'
import EditTryoutDialog from '../components/EditTryoutDialog'
import AddTryoutDialog from '../components/AddTryoutDialog'

const videoAccept: Accept = {
  'video/mp4': ['.mp4'],
  'video/mpeg': ['.mpeg'],
  'video/x-matroska': ['.mkv'],
  'video/quicktime': ['.mov'],
  'video/x-msvideo': ['.avi'],
  'video/x-flv': ['.flv'],
  'video/webm': ['.webm'],
  'video/3gpp': ['.3gp']
}

const log = rootLog.child({ module: 'Tryout' })

function sortByCreatedAt (a: ITryout, b: ITryout): number {
  return a.createdAt > b.createdAt ? 1 : -1
}

function calculateTotalRating (tryouts: ITryout[]): number {
  let totalRating = 0
  for (const tryout of tryouts) {
    if (tryout.rating != null && tryout.level != null) {
      const level = (tryout.level[0] === 'L' ? parseInt(tryout.level.slice(1)) : 1)
      totalRating += tryout.rating + (level - 1) * 5
    }
  }
  return totalRating
}

const emptyBlob = new Blob()

export function Tryout (): ReactElement {
  const { season, tenant, selectedAgeGridOption } = useContext(TenantSeasonContext)
  const { currentPlanInfo } = useSelector((state: RootState) => state.auth)
  const { lastUpdatedVideo, lastUpdatedHeadshot, headshotAdded } = useContext(ClientSyncContext)
  const globalDialog = useContext(GlobalDialogContext)
  const { athleteId } = useParams()
  const {
    data: rawAthlete,
    error: athleteError,
    refetch: refetchAthlete
  } = useGetAthleteQuery({ seasonId: season?.id ?? '', athleteId: athleteId ?? '' }, { skip: season == null || athleteId == null })
  const {
    data: tryouts = [] as ITryout[],
    refetch: refetchTryouts,
    error: tryoutsError
  } = useGetTryoutsQuery({ seasonId: season?.id ?? '', athleteId: athleteId ?? '' }, { skip: season == null || athleteId == null })
  const [addTryout, { error: addTryoutError }] = useAddTryoutMutation()
  const [editTryout, { error: editTryoutError }] = useEditTryoutMutation()
  const [deleteTryout, { error: deleteTryoutError }] = useDeleteTryoutMutation()
  const [editAthlete, { error: editAthleteError }] = useEditAthleteMutation()
  // const [addTryoutVideo] = useAddTryoutVideoMutation()
  const [addTryoutVideo, { error: addTryoutVideoError }] = useAddTryoutVideoSyncMutation()
  // const [addHeadshot] = useAddHeadshotMutation()
  const [deleteAthlete, { error: deleteAthleteError }] = useDeleteAthleteMutation()

  useEffect(() => {
    if (athleteError != null) {
      globalDialog.popupError('Error loading athlete', 'There was an error loading the athlete: ' + rtkErrorToString(athleteError))
      log.error('Error loading athlete', athleteError)
    }
  }, [athleteError, globalDialog])
  useEffect(() => {
    if (tryoutsError != null) {
      globalDialog.popupError('Error loading tryouts', 'There was an error loading the tryouts: ' + rtkErrorToString(tryoutsError))
      log.error('Error loading tryouts', tryoutsError)
    }
  }, [tryoutsError, globalDialog])
  useEffect(() => {
    if (editAthleteError != null) {
      globalDialog.popupError('Error updating athlete', 'There was an error updating the athlete: ' + rtkErrorToString(editAthleteError))
      log.error('Error loading athlete', editAthleteError)
    }
  }, [editAthleteError, globalDialog])
  useEffect(() => {
    if (addTryoutError != null) {
      globalDialog.popupError('Error adding tryout', 'There was an error adding the tryout: ' + rtkErrorToString(addTryoutError))
      log.error('Error adding tryout', addTryoutError)
    }
  }, [addTryoutError, globalDialog])
  useEffect(() => {
    if (editTryoutError != null) {
      globalDialog.popupError('Error updating tryout', 'There was an error updating the tryout: ' + rtkErrorToString(editTryoutError))
      log.error('Error editing tryout', editTryoutError)
    }
  }, [editTryoutError, globalDialog])
  useEffect(() => {
    if (deleteTryoutError != null) {
      globalDialog.popupError('Error deleting tryout', 'There was an error deleting the tryout: ' + rtkErrorToString(deleteTryoutError))
      log.error('Error deleting tryout', deleteTryoutError)
    }
  }, [deleteTryoutError, globalDialog])
  useEffect(() => {
    if (addTryoutVideoError != null) {
      globalDialog.popupError('Error adding tryout video', 'There was an error adding the tryout video: ' + rtkErrorToString(addTryoutVideoError))
      log.error('Error adding tryout video', addTryoutVideoError)
    }
  }, [addTryoutVideoError, globalDialog])
  useEffect(() => {
    if (deleteAthleteError != null) {
      globalDialog.popupError('Error deleting athlete', 'There was an error deleting the athlete: ' + rtkErrorToString(deleteAthleteError))
      log.error('Error deleting athlete', deleteAthleteError)
    }
  }, [deleteAthleteError, globalDialog])
  const athlete: ISeasonAthleteWithAthlete | undefined = useMemo(() => {
    if (rawAthlete == null) return rawAthlete
    return Object.assign({}, rawAthlete, { ageGroups: ageGroupsFromBirthYear(selectedAgeGridOption.id, rawAthlete.birthYear, rawAthlete.lteMay31, rawAthlete.gteJune1) })
  }, [rawAthlete, selectedAgeGridOption])

  useEffect(() => {
    if (lastUpdatedVideo > 0) { // should get retriggered every time this increments
      log.debug('refetchTryouts')
      void refetchTryouts()
    }
    if (lastUpdatedHeadshot > 0) {
      void refetchAthlete()
    }
  }, [lastUpdatedVideo, refetchTryouts, lastUpdatedHeadshot, refetchAthlete])

  const [showAddTryoutDialog, setShowAddTryoutDialog] = useState(false)
  const [showEditTryoutDialog, setShowEditTryoutDialog] = useState(false)
  const [showVideoDialog, setShowVideoDialog] = useState(false)
  const [showLocalVideoDialog, setShowLocalVideoDialog] = useState(false)
  const [showRecordVideoDialog, setShowRecordVideoDialog] = useState(false)
  const [recordVideoTryout, setRecordVideoTryout] = useState<ITryout | null>(null)
  const [thumbnailUrl, setThumbnailUrl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [videoBlob, setVideoBlob] = useState<Blob>(emptyBlob)
  const [isParentMode, setIsParentMode] = useState(false)
  // const [addTryoutType, setAddTryoutType] = useState<string>('')
  // const [addTryoutLevel, setAddTryoutLevel] = useState<string>('')
  // const [addTryoutSkill, setAddTryoutSkill] = useState<string>('')
  // const [addTryoutCustomSkill, setAddTryoutCustomSkill] = useState<string>('')
  // const [editingTryoutId, setEditingTryoutId] = useState('')
  const [editingTryout, setEditingTryout] = useState<ITryout | null>(null)
  // const [showEditTryoutDialog, setShowEditTryoutDialog] = useState(false)
  // const [useCustomTryoutSkill, setUseCustomTryoutSkill] = useState(false)
  // const [tryoutNotes, setTryoutNotes] = useState<Record<string, string>>({})
  const [athleteTemp, setAthleteTemp] = useState<Record<string, string>>({ firstName: '', lastName: '', birthYear: '' })
  const [midYearValue, setMidYearValue] = useState(0)
  const navigate = useNavigate()

  const [videoTitle, setVideoTitle] = useState('Tryout Video')

  const isFlyer = athlete != null && (athlete?.positions ?? []).includes(Position.Flyer)
  const isBase = athlete != null && (athlete?.positions ?? []).includes(Position.Base)
  const isBackspot = athlete != null && (athlete?.positions ?? []).includes(Position.Backspot)
  const isTumbler = athlete != null && (athlete?.positions ?? []).includes(Position.Tumbler)

  const localHeadshots = useLiveQuery(async () => await db.headshots.toArray())
  const [localHeadshot, setLocalHeadshot] = useState<string | null>(null)
  useMemo(() => {
    if (localHeadshots != null) {
      const headshot = localHeadshots.find((h) => h.athleteId === athleteId)
      if (headshot == null) {
        setLocalHeadshot(null)
        return
      }
      db.headshotImages.get(headshot.localId ?? 0).then((localHeadshotImage) => {
        // https://stackoverflow.com/a/70253220
        if (localHeadshotImage != null) {
          const blob = new Blob([localHeadshotImage.headshot], { type: headshot.headshotMimeType })
          setLocalHeadshot(URL.createObjectURL(blob))
        } else {
          log.error('Headshot image not found', headshot)
          setLocalHeadshot(null)
        }
      }).catch((error) => {
        log.error('Exception loading headshot image', headshot, error)
      })
      // return URL.createObjectURL(headshot.headshot)
    }
  }, [localHeadshots, athleteId])

  const localRecordings = useLiveQuery(async () => await db.recordings.toArray())
  const [recordingThumbnails, setRecordingThumbnails] = useState<Record<number, string>>([])
  const [recordingVideos, setRecordingVideos] = useState<Record<number, Blob>>([])
  useEffect(() => {
    // eslint-disable-next-line no-inner-declarations
    async function fetchRecordingsData (): Promise<void> {
      if (localRecordings != null) {
        try {
          const recordings = localRecordings.filter((recording) => recording.athleteId === athleteId)
          const thumbnails: Record<number, string> = {}
          const videos: Record<number, Blob> = {}
          for (const recording of recordings) {
            const video = await db.recordingVideos.get(recording.localId ?? 0)
            if (video != null) {
              videos[recording.localId ?? 0] = video.video
            }
            const thumbnail = await db.recordingThumbnails.get(recording.localId ?? 0)
            if (thumbnail != null) {
              thumbnails[recording.localId ?? 0] = thumbnail.thumbnail
            }
          }
          setRecordingThumbnails(thumbnails)
          setRecordingVideos(videos)
        } catch (error) {
          log.error('Exception loading recordings', error)
        }
      }
    }
    void fetchRecordingsData()
  }, [localRecordings, athleteId])

  const skillDefinition = useMemo<Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>>>(() => {
    const skills = tenant?.settings?.skills ?? skillsByTypeLevel
    const result: Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>> = {} as unknown as Record<TryoutType, Record<SkillLevel, Partial<Record<TryoutSkill, string>>>>
    for (const type in skills) {
      result[type as TryoutType] = {} as unknown as Record<SkillLevel, Partial<Record<TryoutSkill, string>>>
      const inputType: Record<SkillLevel, TryoutSkill[]> = skills[type as TryoutType] ?? ({} as unknown as Record<SkillLevel, TryoutSkill[]>)
      for (const level of skillLevels) {
        result[type as TryoutType][level] = {} as unknown as Partial<Record<TryoutSkill, string>>
        const inputSkillLevel: TryoutSkill[] = inputType[level] ?? ([] as TryoutSkill[])
        for (const skill of inputSkillLevel) {
          result[type as TryoutType][level][skill] = skill
        }
        result[type as TryoutType][level]['Custom' as TryoutSkill] = 'Custom'
      }
    }
    return result
  }, [tenant])

  const needMidYearRange = useMemo(() => {
    if (athleteTemp.birthYear !== '') {
      return birthYearRequiresMidYearRange(parseInt(athleteTemp.birthYear))
    } else {
      return false
    }
  }, [athleteTemp.birthYear])

  useEffect(() => {
    if (athlete != null) {
      const newAthleteTemp: Record<string, string> = {}
      newAthleteTemp.firstName = athlete.firstName
      newAthleteTemp.lastName = athlete.lastName
      newAthleteTemp.birthYear = athlete.birthYear.toString()
      setAthleteTemp(newAthleteTemp)
      if (athlete.birthYear.toString() !== '' && birthYearRequiresMidYearRange(parseInt(athlete.birthYear.toString()))) {
        if (athlete.lteMay31 === true) {
          setMidYearValue(1)
        } else if (athlete.gteJune1 === true) {
          setMidYearValue(2)
        }
      }
    }
  }, [athlete])

  useEffect(() => {
    // we keep a shadow copy of notes for each tryout because React updates them every chara
    if (tryouts != null) {
      /*       const newAddTryoutNotes: Record<string, string> = {}
      for (const tryout of tryouts) {
        newAddTryoutNotes[tryout.id] = tryout.notes ?? ''
      }
      setTryoutNotes(newAddTryoutNotes)
 */
      if (athlete == null || tryouts == null) {
        return
      }
      const totalRating = Math.floor(calculateTotalRating(tryouts))
      if (athlete.totalRating !== totalRating && season != null) {
        void editAthlete({
          seasonId: season.id,
          id: athlete.id,
          ...{ totalRating }
        })
      }
    }
  }, [tryouts, season, editAthlete, athlete])

  // const allTryoutTypes = useMemo(() => {
  //   const result: Record<string, string> = {}
  //   for (const type in skillDefinition) {
  //     result[type] = type
  //   }
  //   return result
  // }, [skillDefinition])
  // log.debug('Render recordings 1', recordings)

  const showVideo = (title?: string, videoUrl?: string, thumbnailUrl?: string): void => {
    setVideoTitle(title ?? 'Tryout Video')
    setVideoUrl(videoUrl ?? '')
    setThumbnailUrl(thumbnailUrl ?? '')
    setShowVideoDialog(true)
  }

  const showLocalVideo = (title?: string, video?: Blob, thumbnail?: string): void => {
    setVideoTitle(title ?? 'Tryout Video')
    setVideoBlob(video ?? emptyBlob)
    setThumbnailUrl(thumbnail ?? '')
    setShowLocalVideoDialog(true)
  }

  const ageGroupCss = (ageGroup: AgeGroup): string => {
    switch (ageGroup) {
      case AgeGroup.Tiny:
        return 'bg-green-50 text-green-700 ring-green-600/20'
      case AgeGroup.Mini:
        return 'bg-yellow-50 text-yellow-700 ring-yellow-600/20'
      case AgeGroup.Youth:
        return 'bg-red-50 text-red-700 ring-red-600/20'
      case AgeGroup.Junior:
        return 'bg-blue-50 text-blue-700 ring-blue-600/20'
      case AgeGroup.Senior:
        return 'bg-indigo-50 text-indigo-700 ring-indigo-600/20'
      default:
        return 'bg-gray-50 text-gray-700 ring-gray-600/20'
    }
  }

  /*
  const onRecord = (recording: Blob): void => {
    log.debug('Add recording', recordings)
    const newRecording = URL.createObjectURL(recording)
    const newRecordings = [...recordings, newRecording]
    log.debug('New recordings:', newRecordings)
    setRecordings(newRecordings)
    // URL.createObjectURL(recording)
  }
 */
  const setAthleteValue = (value: Partial<ISeasonAthleteWithAthleteInput>): void => {
    if (season != null && athleteId != null) {
      void editAthlete({
        seasonId: season.id,
        id: athleteId,
        ...value
      })
    }
  }
  const setAthleteMidYearValue = (value: number): void => {
    if (season != null && athleteId != null && needMidYearRange) {
      const lteMay31 = (needMidYearRange && value === 1)
      const gteJune1 = (needMidYearRange && value === 2)
      setAthleteValue({ lteMay31, gteJune1 })
    }
  }

  const setTryoutValue = (tryoutId: string, value: Partial<ITryout>): void => {
    if (season != null && athleteId != null) {
      void editTryout({
        seasonId: season.id,
        athleteId,
        id: tryoutId,
        ...value
      })
    }
  }

  // const doSetAddTryoutLevel = (level: string): void => {
  //   setAddTryoutLevel(level)
  //   setUseCustomTryoutSkill(false)
  // }

  // const doSetAddTryoutSkill = (skill: string): void => {
  //   if (skill === 'Custom') {
  //     setUseCustomTryoutSkill(true)
  //   } else {
  //     setUseCustomTryoutSkill(false)
  //   }
  //   setAddTryoutSkill(skill)
  // }

  const updatePosition = (position: Position, value: boolean): void => {
    const positions = athlete?.positions ?? []
    const newPositions = value ? [...positions, position] : positions.filter((pos) => pos !== position)
    setAthleteValue({ positions: newPositions })
  }

  const updateLevel = (level: SkillLevel, value: boolean): void => {
    const levels = athlete?.levels ?? []
    const newLevels = value ? [...levels, level] : levels.filter((lvl) => lvl !== level)
    setAthleteValue({ levels: newLevels })
  }
  /*
  const updateTryoutNotes = (tryoutId: string, notes: string): void => {
    const newNotes = Object.assign({}, tryoutNotes, { [tryoutId]: notes })
    setTryoutNotes(newNotes)
  } */

  const updateAthleteTemp = (values: Record<string, string>): void => {
    const newAthleteTemp = Object.assign({}, athleteTemp, values)
    setAthleteTemp(newAthleteTemp)
  }

  const doAddTryout = (tryout: Partial<ITryout>): void => {
    if (season != null && athleteId != null) {
      // const skill = addTryoutSkill === 'Custom' ? addTryoutCustomSkill : addTryoutSkill
      // let tryoutType = addTryoutType as TryoutType
      // if (tryoutType === null || (tryoutType as string) === '') {
      //   tryoutType = TryoutType.Uncategorized
      // }
      void addTryout(Object.assign({}, tryout, {
        seasonId: season.id,
        athleteId,
        notes: ''
      }))
      // setAddTryoutType('')
      // setAddTryoutLevel('')
      // setAddTryoutSkill('')
      // setAddTryoutCustomSkill('')
      // setShowAddTryoutDialog(false)
    }
  }

  const doEditTryout = (tryout: ITryout): void => {
    setEditingTryout(tryout)
    setShowEditTryoutDialog(true)
    // setAddTryoutType(tryout.type ?? '')
    // setAddTryoutLevel(tryout.level ?? '')
    // setAddTryoutSkill(tryout.skill ?? '')
    // setEditingTryoutId(tryout.id)
    // if (tryout.type != null && tryout.type as string !== '' && tryout.level != null && tryout.level as string !== '' && tryout.skill != null && tryout.skill as string !== '') {
    //   if (skillDefinition[tryout.type] != null) {
    //     const options = skillDefinition[tryout.type][tryout.level]
    //     if (options?.[tryout.skill as TryoutSkill] == null) {
    //       setAddTryoutSkill('Custom')
    //       setUseCustomTryoutSkill(true)
    //       setAddTryoutCustomSkill(tryout.skill ?? '')
    //     }
    //   }
    // }
    // setShowEditTryoutDialog(true)
  }

  const doSaveTryout = (tryout: ITryout): void => {
    if (season != null && athleteId != null) {
      // const skill = addTryoutSkill === 'Custom' ? addTryoutCustomSkill : addTryoutSkill
      // let tryoutType = addTryoutType as TryoutType
      // if (tryoutType === null || (tryoutType as string) === '') {
      //   tryoutType = TryoutType.Uncategorized
      // }
      void editTryout(Object.assign({}, tryout, {
        seasonId: season.id,
        athleteId
      }))
      // setAddTryoutType('')
      // setAddTryoutLevel('')
      // setAddTryoutSkill('')
      // setAddTryoutCustomSkill('')
      // setShowEditTryoutDialog(false)
    }
  }
  /*
  const ratingMin = (level: SkillLevel): number => {
    switch (level) {
      case SkillLevel.L1:
        return 1
      case SkillLevel.L2:
        return 6
      case SkillLevel.L3:
        return 11
      case SkillLevel.L4:
        return 16
      case SkillLevel.L5:
        return 21
      case SkillLevel.L6:
        return 26
      default:
        return 0
    }
  }
  const ratingMax = (level: SkillLevel): number => {
    switch (level) {
      case SkillLevel.L1:
        return 5
      case SkillLevel.L2:
        return 10
      case SkillLevel.L3:
        return 15
      case SkillLevel.L4:
        return 20
      case SkillLevel.L5:
        return 25
      case SkillLevel.L6:
        return 30
      default:
        return 0
    }
  } */

  const onDeleteTryout = (tryout: ITryout): void => {
    globalDialog.showWarning('Delete Tryout', 'Are you sure you want to delete this tryout?', 'Delete', () => {
      if (season != null && athleteId != null) {
        void deleteTryout({
          seasonId: season.id,
          athleteId,
          tryoutId: tryout.id
        })
      }
    })
  }

  /*
  const onSave = async (recordingBlob: Blob, mimeType: string, thumbnailUrl: string): Promise<boolean> => {
    // dispatch(setSaving(true))
    log.debug('addTryoutVideo call:')
    if (recordVideoTryout != null) {
      try {
        const recording = await addTryoutVideo({
          seasonId: recordVideoTryout.seasonId,
          athleteId: recordVideoTryout.athleteId,
          tryoutId: recordVideoTryout.id,
          recording: recordingBlob,
          mimeType,
          thumbnailUrl
        }).unwrap()
        log.debug('addTryoutVideo got recording', recording)
        const upload = await uploadVideoFile(recording.uploadUrl, recording.mimeType, recordingBlob)
        if (!upload) {
          console.error('Error uploading video file')
          // globalDialog.showError('Error uploading video file', 'There was an error uploading the video file', () => {
          //   // setShowRecordVideoDialog(false)
          // })
          return false
        }
      } catch (error) {
        console.error('Error uploading video', error)
        // globalDialog.showError('Error uploading video file', 'There was an error uploading the video file: ' + (error as Error).toString(), () => {
        //   // setShowRecordVideoDialog(false)
        // })
        return false
      }
    }
    log.debug('hiding video dialog')
    setShowRecordVideoDialog(false)
    return true
  }
  */

  const onDropHeadshot = async (file: File): Promise<void> => {
    if (season == null || athlete == null) {
      log.debug('onDropHeadshot: currentSeasonId or athlete is null', season, athlete)
      return
    }
    // https://stackoverflow.com/a/70253220

    const newHeadshotId = await db.headshots.add({
      athleteId: athlete.id,
      tenantId: tenant?.id ?? '',
      seasonId: season.id,
      uploadStatus: UploadStatus.NotStarted,
      uploadAttempts: 0,
      createdAt: new Date(),
      // headshot: file,
      // headshot: await file.arrayBuffer(),
      headshotMimeType: file.type
    })
    log.debug('Saved headshot metadata with id', newHeadshotId)
    const headshotImageId = await db.headshotImages.add({
      localId: newHeadshotId,
      headshot: await file.arrayBuffer()
    })
    log.debug('Saved headshot data with id', headshotImageId)
    headshotAdded(newHeadshotId ?? 0)
  }

  const onDropVideo = async (file: File, tryout: ITryout): Promise<void> => {
    if (season == null || athlete == null || tryout == null) {
      log.debug('onDropVideo: currentSeasonId or athlete is null', season, athlete)
      return
    }
    try {
      const recording = await addTryoutVideo({
        seasonId: season.id, athleteId: athlete.id, tryoutId: tryout.id, recording: file, mimeType: file.type
      }).unwrap()
      log.debug('addTryoutVideo got recording', recording)
      const upload = await uploadVideoFile(recording.uploadUrl, recording.mimeType, file)
      if (!upload) {
        console.error('Error uploading video file')
        // globalDialog.showError('Error uploading video file', 'There was an error uploading the video file', () => {
        //   // setShowRecordVideoDialog(false)
        // })
      }
    } catch (error) {
      console.error('Error uploading video', error)
      // globalDialog.showError('Error uploading video file', 'There was an error uploading the video file: ' + (error as Error).toString(), () => {
      //   // setShowRecordVideoDialog(false)
      // })
    }
    // await addTryoutVideo({ seasonId: season.id, athleteId: athlete.id, tryoutId: tryout.id, recording: file, mimeType: file.type })
  }

  const buttonCss = (enabled: boolean): string => enabled ? 'px-2 py-1 text-base text-white bg-brand-600 dark:bg-brand-500 ring-dark-300 ring' : 'px-2 py-1 text-base text-gray-500 dark:text-dark-300 bg-gray-100 dark:bg-dark-700 ring-gray-400/20'
  // log.debug('Render recordings 2', recordings)
  // <ReactPlayer className="h-16" url={jumpVideo} />
  if (athlete == null) {
    return (
      <Container className='gap-y-0 gap-x-4' columns={12}>
        <ContainerItem columns={9}>
          <PageHeader
            title='Athlete Tryout'
          >
          </PageHeader>
        </ContainerItem>
      </Container>
    )
  }

  const onDeleteAthlete = (): void => {
    globalDialog.showWarning('Delete Athlete', 'Are you sure you want to delete this athlete?', 'Delete Athlete', () => {
      const doDelete = async (): Promise<void> => {
        log.debug('Deleting athlete', athleteId)
        await deleteAthlete({ seasonId: season?.id ?? '', athleteId: athleteId ?? '' })
        navigate('/')
      }
      void doDelete()
    })
  }

  const hasLevel = (level: string): boolean => {
    return athlete != null && (athlete?.levels ?? []).includes(level as SkillLevel)
  }

  const canAddTryouts = currentPlanInfo?.maxTryouts == null || tryouts.length < currentPlanInfo.maxTryouts

  return (
    <>
      {/* <Dialog title='Add Tryout' open={showAddTryoutDialog} onClose={() => { setShowAddTryoutDialog(false) }} buttons={
        <>
          <Button label='Add' disabled={addTryoutType === '' || addTryoutLevel === '' || addTryoutSkill === ''} primary onClick={() => { doAddTryout() }} />
          <Button label='Skip' onClick={() => { doAddTryout() }} />
          <Button label='Cancel' onClick={() => { setShowAddTryoutDialog(false) }} />
        </>
      }>
        <Form className='gap-x-6 gap-y-6'>
          <RadioButtons label='Tryout Type' columns={12} value={addTryoutType} onChange={(value) => { setAddTryoutType(value); setAddTryoutLevel(''); setAddTryoutSkill('') }} options={allTryoutTypes} />
          {addTryoutType !== '' &&
            <RadioButtons label='Level' columns={12} value={addTryoutLevel} onChange={(value) => { doSetAddTryoutLevel(value); setAddTryoutSkill('') }} options={skillLevelValues} />
          }
          {addTryoutType !== '' && addTryoutLevel !== '' &&
            <RadioButtons label='Skill' columns={12} value={addTryoutSkill} onChange={(value) => { doSetAddTryoutSkill(value) }} options={skillDefinition[addTryoutType as TryoutType][addTryoutLevel as SkillLevel]} />
          }
          {addTryoutType !== '' && addTryoutLevel !== '' && useCustomTryoutSkill &&
            <TextBox label='Custom Skill' columns={12} value={addTryoutCustomSkill} onChange={setAddTryoutCustomSkill} />
          }
        </Form>
      </Dialog> */}
      <AddTryoutDialog
        showDialog={showAddTryoutDialog}
        setShowDialog={setShowAddTryoutDialog}
        addTryout={doAddTryout}
        skillDefinition={skillDefinition}
      />
      <EditTryoutDialog
        showDialog={showEditTryoutDialog}
        setShowDialog={setShowEditTryoutDialog}
        tryout={editingTryout}
        saveTryout={doSaveTryout}
        skillDefinition={skillDefinition}
      />
      {/* <Dialog title='Edit Tryout' open={showEditTryoutDialog} onClose={() => { setShowEditTryoutDialog(false) }} buttons={
        <>
          <Button label='Save' disabled={addTryoutType === '' || addTryoutLevel === '' || addTryoutSkill === ''} primary onClick={() => { doSaveTryout() }} />
          <Button label='Cancel' onClick={() => { setShowEditTryoutDialog(false) }} />
        </>
      }>
        <Form>
          <RadioButtons label='Tryout Type' columns={12} value={addTryoutType} onChange={(value) => { setAddTryoutType(value); setAddTryoutLevel(''); setAddTryoutSkill('') }} options={allTryoutTypes} />
          {addTryoutType !== '' &&
            <RadioButtons label='Level' columns={12} value={addTryoutLevel} onChange={(value) => { doSetAddTryoutLevel(value); setAddTryoutSkill('') }} options={skillLevelValues} />
          }
          {addTryoutType !== '' && addTryoutLevel !== '' &&
            <RadioButtons label='Skill' columns={12} value={addTryoutSkill} onChange={(value) => { doSetAddTryoutSkill(value) }} options={skillDefinition[addTryoutType as TryoutType][addTryoutLevel as SkillLevel]} />
          }
          {addTryoutType !== '' && addTryoutLevel !== '' && useCustomTryoutSkill &&
            <TextBox label='Custom Skill' columns={12} value={addTryoutCustomSkill} onChange={setAddTryoutCustomSkill} />
          }
        </Form>
      </Dialog> */}
      <PlayVideoDialog autoPlay title={videoTitle} open={showVideoDialog} onClose={() => { setShowVideoDialog(false) }} thumbnailUrl={thumbnailUrl} videoUrl={videoUrl ?? ''} onEnded={() => { setShowVideoDialog(false) }} />
      <LocalPlayVideoDialog autoPlay title={videoTitle} open={showLocalVideoDialog} onClose={() => { setShowLocalVideoDialog(false) }} thumbnail={thumbnailUrl} video={videoBlob ?? emptyBlob} onEnded={() => { setShowLocalVideoDialog(false) }} />
      {recordVideoTryout != null &&
        <RecordVideoDialog athleteId={athleteId ?? ''} tenantId={tenant?.id ?? ''} seasonId={season?.id ?? ''} tryoutId={recordVideoTryout.id} open={showRecordVideoDialog} onClose={() => { setShowRecordVideoDialog(false) }} />
      }
      <Container className='gap-y-0 gap-x-4' columns={12}>
        <ContainerItem columns={9}>
          <PageHeader
            title='Athlete Tryout'
          >
            <Button Icon={TrashIcon} className='opacity-50' transparent onClick={onDeleteAthlete} />
          </PageHeader>
          <Form>
            <TextBox value={athleteTemp.firstName} onBlur={(value) => { setAthleteValue({ firstName: value }) }} onChange={(value) => { updateAthleteTemp({ firstName: value }) }} label='First Name' placeholder='First Name' columns={needMidYearRange ? 2 : 3} />
            <TextBox value={athleteTemp.lastName} onBlur={(value) => { setAthleteValue({ lastName: value }) }} onChange={(value) => { updateAthleteTemp({ lastName: value }) }} label='Last Name' placeholder='Last Name' columns={needMidYearRange ? 2 : 3} />
            <TextBox value={athleteTemp.birthYear} onBlur={(value) => { setAthleteValue({ birthYear: Number(value) }) }} onChange={(value) => { updateAthleteTemp({ birthYear: value }) }} label='Birth Year' placeholder='Birth Year' columns={needMidYearRange ? 2 : 3} />
            {needMidYearRange &&
              <div className='sm:col-span-3'>
                <label>Birth Day</label>
                <fieldset className="">
                  <div className="flex items-center">
                    <input
                      id='lteMay31'
                      type="radio"
                      checked={midYearValue === 1}
                      onChange={(e) => { if (e.currentTarget.checked) { setMidYearValue(1); setAthleteMidYearValue(1) } }}
                      className="!py-0"
                    />
                    <label htmlFor='lteMay31' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                      Jan 1 - May 31
                    </label>
                  </div>
                  <div className="flex items-center -mt-1 ">
                    <input
                      id='gteJun1'
                      type="radio"
                      checked={midYearValue === 2}
                      onChange={(e) => { if (e.currentTarget.checked) { setMidYearValue(2); setAthleteMidYearValue(2) } }}
                      className="!py-0"
                    />
                    <label htmlFor='lteMay31' className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                      Jun 1 - Dec 31
                    </label>
                  </div>
                </fieldset>
              </div>
            }
            <ContainerItem columns={2}>
              <label>Age Eligibility</label>
              {athlete?.ageGroups?.map((ageGroup, idx) => (
                <div key={idx} className={classes('mr-2 inline-flex flex-shrink-0 items-center rounded-full mt-2.5 px-2 py-1 text-sm font-medium ring-1 ring-inset', ageGroupCss(ageGroup))}>{ageGroup}</div>
              ))}
            </ContainerItem>
            <ContainerItem columns={1}>
              <label>Total</label>
              <Rating className='mt-2.5' value={athlete?.totalRating} small />
            </ContainerItem>
          </Form>
        </ContainerItem>
        <ContainerItem className='flex flex-col items-end w-full' columns={3}>
          {localHeadshot != null
            ? (
              <div className='w-32 h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 relative'>
                <img
                  src={localHeadshot}
                  className='w-32 h-32 rounded-lg md:w-40 md:h-40 object-cover lg:w-48 lg:h-48'
                />
                <CloudArrowUpIcon className='absolute text-gray-50 dark:text-dark-50 bottom-0 left-0 w-6 h-6' />
              </div>
              )
            : (
              <ImageFileDropButton
                url={athlete?.headshotUrl ?? unknownUser}
                onDrop={onDropHeadshot}
                className='w-32 h-32 rounded-lg md:w-40 md:h-40 lg:w-48 lg:h-48'
                imageClassName='w-32 h-32 rounded-lg md:w-40 md:h-40 lg:w-48 lg:h-48'
                AcceptIcon={CameraIcon}
              />
              )
          }
        </ContainerItem>
        <ContainerItem className='mt-2' columns={12}>
          <div className='flex-row flex-wrap items-center mb-2 sm:space-x-8 sm:flex'>
            {((athlete?.positionsRequested != null && athlete.positionsRequested.length > 0) || (athlete?.levelsRequested != null && athlete.levelsRequested.length > 0)) &&
              <div className='flex flex-row items-center mb-2 space-x-2 sm:flex-grow'>
                <span>
                  Trying out for:&nbsp;
                  {athlete?.positionsRequested?.map((position) => position).join(', ')}
                  &nbsp;&ndash;&nbsp;
                  {athlete?.levelsRequested?.map((level) => level).join(', ')}
                </span>
              </div>
            }
            <div className='flex flex-row items-center mb-2 space-x-2 sm:flex-grow'>
              <span>Coach determination: </span>
              <button className={classes('inline-flex flex-shrink-0 items-center rounded-full font-medium ring-1 ring-inset', buttonCss(isFlyer))} onClick={() => { updatePosition(Position.Flyer, !isFlyer) }}>Flyer</button>
              <button className={classes('inline-flex flex-shrink-0 items-center rounded-full font-medium ring-1 ring-inset', buttonCss(isBase))} onClick={() => { updatePosition(Position.Base, !isBase) }}>Base</button>
              <button className={classes('inline-flex flex-shrink-0 items-center rounded-full font-medium ring-1 ring-inset', buttonCss(isBackspot))} onClick={() => { updatePosition(Position.Backspot, !isBackspot) }}>Backspot</button>
              <button className={classes('inline-flex flex-shrink-0 items-center rounded-full font-medium ring-1 ring-inset', buttonCss(isTumbler))} onClick={() => { updatePosition(Position.Tumbler, !isTumbler) }}>Tumbler</button>
            </div>
            <div className='flex flex-row items-center mb-2 space-x-2 sm:flex-grow'>
              {allLevels.map((level) => (
                <button key={level} className={classes('inline-flex flex-shrink-0 items-center rounded-full font-medium ring-1 ring-inset', buttonCss(hasLevel(level)))} onClick={() => { updateLevel(level as SkillLevel, !hasLevel(level)) }}>{level}</button>
              ))}
            </div>
            <div className='flex flex-row items-center mb-2 space-x-2'>
              <Button Icon={ParentIcon} label={isParentMode ? undefined : 'Parent Mode'} onClick={() => { setIsParentMode(!isParentMode) }} primary={isParentMode} />
            </div>
          </div>
        </ContainerItem>
      </Container>

      {tryouts?.slice().sort(sortByCreatedAt)?.map((tryout) => (
        <div key={tryout.id} className='grid mt-4 text-center border border-gray-400 divide-x divide-gray-400 rounded-tl-lg rounded-tr-lg lg:grid-cols-7'>
          <div className='text-white bg-gray-900 rounded-tl-lg rounded-tr-lg lg:col-span-7 dark:bg-gray-700'>
            {tryout.type}{' ' + tryout.level}
          </div>
          <div className='relative flex flex-col w-full lg:col-span-1'>
            <div className='flex-grow flex justify-evenly items-center w-full text-center'>{tryout.type === TryoutType.Uncategorized ? 'Uncategorized' : tryout.skill}</div>
            <div className='w-full flex flex-row-reverse pb-2'>
              <Button className='opacity-50' Icon={TrashIcon} onClick={() => { onDeleteTryout(tryout) }} transparent />
              <Button className='' Icon={PencilIcon} label='Edit' onClick={() => { doEditTryout(tryout) }} />
            </div>
          </div>
          <div className='px-2 text-left lg:col-span-3'>
            <div className={isParentMode ? 'hidden' : ''}>
              <TextArea label='Notes' updateOnBlur value={tryout.notes ?? ''} onChange={(value) => { log.debug('stv', value); setTryoutValue(tryout.id, { notes: value }) }} rows={4} />
            </div>
          </div>
          <div className='flex flex-col px-2 lg:col-span-1'>
            <label className='w-full text-left'>Rating</label>
            <div className='flex flex-row items-center flex-grow'>
              <div className='flex flex-col items-center w-full'>
                <Rating value={tryout.rating} min={1} max={5} onChange={(value) => { setTryoutValue(tryout.id, { rating: value }) }} />
              </div>
            </div>
          </div>
          <div className='flex flex-col items-center px-2 lg:col-span-2'>
            <label className='w-full text-left'>Videos</label>
            <div className='flex flex-row items-center flex-grow pb-2'>
              <div className='flex flex-row flex-wrap items-center w-full'>
                {tryout.recordings?.map((recording) => (
                  <VideoPreview key={recording.id} className='w-16 h-32 mr-4' thumbUrl={recording.thumbnailUrl} videoUrl={recording.videoUrl ?? ''} onClick={() => { showVideo(athleteTemp.firstName + ' ' + athleteTemp.lastName, recording.videoUrl, recording.thumbnailUrl) }} />
                ))}
                {localRecordings?.filter((recording) => recording.athleteId === athleteId && recording.tryoutId === tryout.id).map((recording) => (
                  <LocalVideoPreview key={recording.localId} className='w-16 h-32 mr-4' thumbnail={recordingThumbnails[recording.localId ?? 0] ?? ''} onClick={() => { showLocalVideo(athleteTemp.firstName + ' ' + athleteTemp.lastName, recordingVideos[recording.localId ?? 0], recordingThumbnails[recording.localId ?? 0]) }} />
                ))}
                {(currentPlanInfo?.maxVideos == null || tryout.recordings == null || tryout.recordings.length < currentPlanInfo.maxVideos) &&
                  <div className='flex flex-row items-center w-16 h-32 mr-4'>
                    <RecordButton onClick={() => { setRecordVideoTryout(tryout); setShowRecordVideoDialog(true) }} />
                    <ImageFileDropButton round DefaultIcon={ArrowUpTrayIcon} className='ml-2 flex-none w-10 h-10 twc-secondary' svgClassName='w-6 h-6' onDrop={async (file) => { await onDropVideo(file, tryout) }} accept={videoAccept} />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className='py-4'>
        <Button Icon={canAddTryouts ? PlusCircleIcon : NoSymbolIcon} primary label={canAddTryouts ? 'Add Tryout' : `Max Tryouts on ${currentPlanInfo?.name ?? 'Current'} Plan`} disabled={!canAddTryouts} onClick={() => { setShowAddTryoutDialog(true) }} />
      </div>
    </>
  )
}

export default Tryout
