import { type ReactElement, useContext, useRef, useState, useEffect } from 'react'

import { Button, Card, Form, FormButtons, ImageFileDropButton, LoadingComponent, PageHeader, TextBox } from '@andyneville/tailwind-react'
import { TenantSeasonContext } from '../components/TenantSeasonProvider'
import { skillsByTypeLevel, type IMyAccountInput, type ITenant, type SkillLevel, type TryoutSkill, type TryoutType, tenantPlans, TenantRole, AccountRole } from '../../../api/api'
import { useAddLogoMutation, useEditTenantMutation } from '../reducers/apiSlice-tenants'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

import unknownLogo from '../assets/logo2.png'
import { useEditMyAccountMutation } from '../reducers/apiSlice'
import { rootLog } from '../logging'
import { useSelector } from 'react-redux'
import { type RootState } from '../store'
import { DateTime } from 'luxon'
import CustomSkills from '../components/CustomSkills'

const log = rootLog.child({ module: 'Settings' })

function copySkills (input: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>): Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> {
  const result: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> = {} as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
  for (const type of Object.keys(input) as TryoutType[]) {
    result[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
    for (const level of Object.keys(input[type]) as SkillLevel[]) {
      result[type][level] = [...input[type][level]]
    }
  }
  return result
}

function cleanSkills (input: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>): Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> {
  const result: Record<TryoutType, Record<SkillLevel, TryoutSkill[]>> = {} as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>
  for (const type of Object.keys(input) as TryoutType[]) {
    result[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
    for (const level of Object.keys(input[type]) as SkillLevel[]) {
      result[type][level] = [...input[type][level]].filter((skill) => skill.trim() !== '')
    }
  }
  return result
}

export default function Settings (): ReactElement {
  const { currentTenant, roles } = useSelector((state: RootState) => state.auth)
  const { me, tenant } = useContext(TenantSeasonContext)
  const [editTenant] = useEditTenantMutation()
  const [editMyAccount] = useEditMyAccountMutation()
  const [addLogo] = useAddLogoMutation()
  const firstNameRef = useRef<HTMLInputElement>(null)
  const [customSkills, setCustomSkills] = useState<Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>>(skillsByTypeLevel as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>)
  const [loadedSkills, setLoadedSkills] = useState(false)
  const [skillsDirty, setSkillsDirty] = useState(false)

  useEffect(() => {
    if (tenant?.settings?.skills != null && !loadedSkills) {
      log.debug('setting initial skills', tenant.settings.skills)
      setCustomSkills(tenant.settings.skills)
      setLoadedSkills(true)
    }
  }, [tenant, loadedSkills])

  const doUpdateMe = async (value: Partial<IMyAccountInput>): Promise<void> => {
    if (tenant != null) {
      const update = { ...value }
      if (update.firstName != null || update.lastName != null) {
        update.name = `${update.firstName ?? me?.firstName} ${update.lastName ?? me?.lastName}`
      }
      await editMyAccount(update)
    }
  }

  const doUpdateTenant = async (value: Partial<ITenant>): Promise<void> => {
    if (tenant != null) {
      await editTenant({
        id: tenant.id,
        ...value
      })
    }
  }

  const onDropLogo = async (file: File): Promise<void> => {
    if (tenant == null) {
      log.debug('onDropLogo: tenant is null')
      return
    }
    await addLogo({ tenantId: tenant.id, file })
  }

  const setCustomSkill = (type: TryoutType, level: SkillLevel, index: number, value: string): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[type][level][index] = value as TryoutSkill
      setSkillsDirty(true)
      return newSkills
    })
  }

  const addCustomSkill = (type: TryoutType, level: SkillLevel): void => {
    log.debug('addCustomSkill', type, level)
    const newSkill = '' as TryoutSkill
    setCustomSkills((prev) => {
      log.debug('prev', prev)
      const newSkills = copySkills(prev)
      log.debug('new', newSkills)
      if (newSkills[type] == null) {
        newSkills[type] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
      }
      if (newSkills[type][level] == null) {
        newSkills[type][level] = [] as unknown as TryoutSkill[]
      }
      newSkills[type][level].push(newSkill) // = [...prev[type][level], newSkill]
      log.debug('final', newSkills)
      setSkillsDirty(true)
      return newSkills
    })
  }

  const removeCustomSkill = (type: TryoutType, level: SkillLevel, index: number): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[type][level].splice(index, 1) // = prev[type][level].filter((_, idx) => idx !== index)
      setSkillsDirty(true)
      return newSkills
    })
  }

  const addTryoutType = (): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills['New Custom Type' as TryoutType] = {} as unknown as Record<SkillLevel, TryoutSkill[]>
      setSkillsDirty(true)
      return newSkills
    })
  }

  const removeTryoutType = (type: TryoutType): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      delete newSkills[type] // eslint-disable-line @typescript-eslint/no-dynamic-delete
      setSkillsDirty(true)
      return newSkills
    })
  }

  const renameTryoutType = (oldType: TryoutType, newType: TryoutType): void => {
    setCustomSkills((prev) => {
      const newSkills = copySkills(prev)
      newSkills[newType] = newSkills[oldType]
      delete newSkills[oldType] // eslint-disable-line @typescript-eslint/no-dynamic-delete
      setSkillsDirty(true)
      return newSkills
    })
  }

  const saveSkills = async (): Promise<void> => {
    if (tenant != null) {
      const cleanedSkills = cleanSkills(customSkills)
      await editTenant({
        id: tenant.id,
        settings: { ...tenant.settings, skills: cleanedSkills }
      })
      setSkillsDirty(false)
    }
  }

  const resetSkills = (): void => {
    setCustomSkills(skillsByTypeLevel as unknown as Record<TryoutType, Record<SkillLevel, TryoutSkill[]>>)
    setSkillsDirty(true)
  }

  let planElement: ReactElement | undefined
  if (currentTenant != null) {
    const expiration = currentTenant.expiration != null ? new Date(currentTenant.expiration) : undefined
    let expirationText: string | undefined
    if (expiration != null) {
      const daysRemaining = Math.floor((expiration.getTime() - Date.now()) / (1000 * 60 * 60 * 24))
      if (daysRemaining >= 7) {
        expirationText = DateTime.fromJSDate(expiration).toLocaleString(DateTime.DATE_SHORT)
      } else {
        expirationText = DateTime.fromJSDate(expiration).toLocaleString(DateTime.DATETIME_SHORT)
      }
    }
    const planInfo = tenantPlans[currentTenant.plan]
    if (planInfo != null) {
      planElement = <div className="flex items-center px-3 py-1.5 -mt-4 text-base text-white rounded-full bg-brand-600 text-nowrap">{planInfo.name} Plan{expirationText != null ? ': expires ' + expirationText : ''}</div>
    }
  }

  return (
    <>
      <PageHeader
        title='Settings'
        subtitle={tenant?.name}
      >
      </PageHeader>
      <LoadingComponent isLoading={false}>
        <Card title='Account Settings' border divider>
          <Form className='gap-4 mt-4' autofocus={firstNameRef}>
            <TextBox ref={firstNameRef} columns={4} label='First Name' value={me?.firstName} updateOnBlur onChange={(value) => { void doUpdateMe({ firstName: value }) }} />
            <TextBox columns={4} label='Last Name' value={me?.lastName} updateOnBlur onChange={(value) => { void doUpdateMe({ lastName: value }) }} />
          </Form>
        </Card>
        {(roles.includes(TenantRole.Owner) || roles.includes(TenantRole.Manager) || roles.includes(AccountRole.SuperAdministrator) || roles.includes(AccountRole.Administrator)) &&
          <Card title='Organization Settings' border divider className='mt-4' buttons={planElement}>
            <div className='w-full sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start'>
              <Form className='sm:col-span-3'>
                <TextBox label='Organization Name' value={tenant?.name} updateOnBlur onChange={(value) => { void doUpdateTenant({ name: value }) }} />
              </Form>
              <Form>
                <label>Logo</label>
                <ImageFileDropButton
                  url={tenant?.logo}
                  defaultUrl={unknownLogo}
                  onDrop={onDropLogo}
                  className='w-32 h-32 rounded-lg sm:mt-8 md:w-40 md:h-40 lg:w-48 lg:h-48'
                  imageClassName='w-32 h-32 rounded-lg md:w-40 md:h-40 lg:w-48 lg:h-48'
                  AcceptIcon={ArrowDownTrayIcon}
                />
              </Form>
            </div>
          </Card>
        }
        {(roles.includes(TenantRole.Owner) || roles.includes(TenantRole.Manager) || roles.includes(AccountRole.SuperAdministrator) || roles.includes(AccountRole.Administrator)) &&
          <Card title='Custom Skills' border divider className='mt-4'>
            <Form className='mt-4 gap-y-4'>
              <CustomSkills customSkills={customSkills} addCustomSkill={addCustomSkill} removeCustomSkill={removeCustomSkill} setCustomSkill={setCustomSkill} renameTryoutType={renameTryoutType} removeTryoutType={removeTryoutType} />
              <FormButtons>
                <Button label='Add New Custom Type' onClick={() => { addTryoutType() }} />
                <Button label='Save' primary disabled={!skillsDirty} onClick={() => { void saveSkills() }} />
                <Button label='Reset to Default' onClick={resetSkills} />
              </FormButtons>

            </Form>
          </Card>
        }
      </LoadingComponent>
    </>
  )
}
